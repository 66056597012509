import { signal, SignalValue } from "omi";
import groupBy from "lodash-es/groupBy";
import includes from "lodash-es/includes";
import orderBy from "lodash-es/orderBy";

import { initApi } from "@/utils/api";
import { networkSignal } from "@/utils/signals";
import { Project } from "@/api";

import { getOrder, getSelectedTime } from "../../utils";
import { OrderBy, SortBy } from "../models";

const myProjects = networkSignal<Project[]>({ default: [] });
const projectsByCategory = signal<[string, Project[]][]>([]);

export function useMyProjects() {
  const fetch = async () => {
    const res = await myProjects.run(getMyProjects);

    if (res) {
      projectsByCategory.value = orderAndGroup(res);
    }
  };

  return {
    fetch,
    ...myProjects.signal,

    getProjectsByCategory: getProjectsByCategorySelector(projectsByCategory),
    addToRoadmap: addToRoadmapFunc(),
    removeFromRoadmap: removeFromRoadmapFunc(),
  };
}

async function getMyProjects(): Promise<Project[]> {
  const api = initApi();
  return await api.getProjects({ selected: "true" });
}

function addToRoadmapFunc(): (projects: Project[]) => void {
  return (_projects: Project[]) => {
    const existingProjects = myProjects.signal.data.value || [];

    const existingIds = new Set(existingProjects.map(p => p.id));
    const newProjects = [];

    for (let i = 0; i < _projects.length; i++) {
      const project = _projects[i];
      if (!existingIds.has(project.id)) {
        project.selected = true;
        project.selectedTime = getSelectedTime(project, true);
        newProjects.push(project);
      }
    }

    if (newProjects.length > 0) {
      const projects = [...existingProjects, ...newProjects];
      myProjects.signal.data.value = projects;
      projectsByCategory.value = orderAndGroup(projects);
    }
  };
}

function removeFromRoadmapFunc(): (ids: string[]) => void {
  return (ids: string[]) => {
    ids.forEach(id => {
      const project = myProjects.signal.data.value?.find(p => p.id === id);
      if (project) {
        project.selected = false;
        project.selectedTime = undefined;
      }
    });

    const projects = myProjects.signal.data.value?.filter(p => !includes(ids, p.id)) || [];
    myProjects.signal.data.value = projects;
    projectsByCategory.value = orderAndGroup(projects);
  };
}

function groupProjects(projects: Project[]): [string, Project[]][] {
  const selectedProjects = projects.filter(p => p.selected);
  const groupByCategory = groupBy(selectedProjects, p => p.metadata?.category || "Misc");
  return Object.entries(groupByCategory);
}

function orderProjects(groupedProjects: [string, Project[]][]): [string, Project[]][] {
  const orderedByCategory = orderBy(groupedProjects, ([category]) => category);
  return orderedByCategory.map(([category, projects]) => [
    category,
    orderBy(projects, (p, idx) => [getOrder(p)?.seriesOrder || idx, getOrder(p)?.projectOrder || idx]),
  ]);
}

function orderAndGroup(projects: Project[]): [string, Project[]][] {
  return orderProjects(groupProjects(projects));
}

function getProjectsByCategorySelector(
  projectsByCategory: SignalValue<[string, Project[]][]>,
): (sortBy: SortBy, orderBy: OrderBy) => [string, Project[]][] {
  return (sortBy: SortBy, _orderBy: OrderBy) => {
    let sortedProjects = projectsByCategory.value;

    if (projectsByCategory.value.length === 0) {
      return [];
    }

    if (_orderBy === OrderBy.TOPIC) {
      sortedProjects = orderBy(sortedProjects, ([category]) => category.toLowerCase(), sortBy);
    }

    if (_orderBy === OrderBy.RECENTLY_ADDED) {
      const sortByReverse = sortBy === SortBy.ASC ? SortBy.DESC : SortBy.ASC;
      const projects = myProjects.signal.data.value;
      const sortedProjects = orderBy(projects, p => p.selectedTime, sortByReverse);
      return [["", sortedProjects]];
    }

    if (_orderBy === OrderBy.RECOMMENDED) {
      const projects = myProjects.signal.data.value;
      const sortedProjects = orderBy(
        projects,
        (p, idx) => [getOrder(p)?.seriesOrder || idx, getOrder(p)?.projectOrder || idx],
        sortBy,
      );
      return [["", sortedProjects]];
    }

    return sortedProjects;
  };
}
