import { classNames, h } from "omi";
import { isFunction } from "lodash-es";

import { IconName } from "@/components/atoms/nw-icon/icons";
import { NWIcon, NWTooltip } from "@/components/atoms";

type ToolbarLink = {
  type: "link";
  name: string;
  icon: IconName;
  href: string;
};

type ToolbarButton = {
  type: "button";
  color: "gray" | "brand" | "gold" | "red";
  name: string;
  iconLeft?: IconName;
  iconRight?: IconName;
  iconOnly?: boolean;
  semibold?: boolean;
  onClick: () => void;
  active: boolean | (() => boolean);
  tooltipText?: string;
  withTooltip?: boolean;
};

type ToolbarDivider = {
  type: "divider";
};

export type ToolbarItem = ToolbarLink | ToolbarButton | ToolbarDivider;

export function ToolbarItem(props: ToolbarItem) {
  const classes = "flex items-center p-3 space-x-2 text-gray-900 hover:bg-gray-100 rounded-lg transition-all";

  if (props.type === "divider") {
    return <div class="h-11 border-r border-gray-200 w-1"></div>;
  }

  if (props.type === "link") {
    return (
      <NWTooltip placement="top" content={props.name}>
        <a href={(props as ToolbarLink).href} target="_blank" class={classes}>
          <NWIcon name={props.icon} width={24} height={24} />
        </a>
      </NWTooltip>
    );
  }

  const isActive = isFunction(props.active) ? props.active() : props.active;
  return (
    <NWTooltip placement="top" content={props.tooltipText ?? props.name} shouldShow={props.withTooltip ?? true}>
      <button
        onClick={(props as ToolbarButton).onClick}
        class={classNames(classes, { "bg-brand-50 rounded-lg": isActive })}
      >
        <div
          class={classNames(
            "shrink-0 transition-all",
            { "text-brand-600": isActive },
            { "flex items-center space-x-1.5": !props.iconOnly },
            { "text-brand-700": props.color === "brand" },
            { "text-warning-400": props.color === "gold" },
            { "text-error-400": props.color === "red" },
          )}
        >
          {props.iconLeft && <NWIcon name={props.iconLeft} width={20} height={20} />}
          {!props.iconOnly && <p className={classNames({ "font-semibold": props.semibold })}>{props.name}</p>}
          {props.iconRight && <NWIcon name={props.iconRight} width={20} height={20} />}
        </div>
      </button>
    </NWTooltip>
  );
}
