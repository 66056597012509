import { classNames, h } from "omi";

import { ProjectStatus } from "@/api";
import { NWTooltip } from "@/components";

type Props = {
  isActive: boolean;
  status: ProjectStatus | undefined;
  size: "sm" | "md" | "lg";
};

export function ProjectStatusIndicator({ status = ProjectStatus.PROJECT_INCOMPLETE, isActive, size = "md" }: Props) {
  if (status === ProjectStatus.PROJECT_COMPLETE) {
    return (
      <NWTooltip placement="top" content="Completed 🎉" strategy="absolute">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
            fill="#47CD89"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0274 4.205L6.679 9.36668L5.25972 7.8503C4.99828 7.60379 4.58743 7.58885 4.28864 7.79801C3.99732 8.01464 3.91515 8.3956 4.09442 8.70186L5.77514 11.4358C5.93948 11.6898 6.22334 11.8467 6.54454 11.8467C6.85081 11.8467 7.14213 11.6898 7.30647 11.4358C7.57538 11.0848 12.7072 4.96693 12.7072 4.96693C13.3795 4.2797 12.5653 3.67464 12.0274 4.19753V4.205Z"
            fill="#ECFDF3"
          />
        </svg>
      </NWTooltip>
    );
  }

  return (
    <NWTooltip placement="top" content={isActive ? "Current project" : "Not completed"} strategy="absolute">
      <div
        class={classNames(
          "shrink-0 rounded-full border",
          {
            "w-3 h-3": size === "sm",
            "w-4 h-4": size === "md",
            "w-5 h-5": size === "lg",
          },
          {
            "border-brand-600 bg-brand-600": isActive,
            "border-gray-300 bg-white": !isActive && status === ProjectStatus.PROJECT_INCOMPLETE,
          },
          {
            // "border-success-400 bg-success-100": status === ValidationStatus.InReview,
          },
        )}
      ></div>
    </NWTooltip>
  );
}
