import { h, Component, tag } from "omi";
import { tailwind } from "@/tailwind";
import { Store } from "@/store";
import { NWIcon } from "@/components/atoms";
import { clearEditorChanges, toggleEditorEditing } from "@/editor/events";

type Props = {};
@tag("toolbar-views-admin")
export class NWToolbarViewAdmin extends Component<Props> {
  static css = [tailwind];

  render() {
    if (!Store.features.isAdmin) {
      // Extra safeguard just in case
      return null;
    }

    return (
      <div class="bg-white border border-gray-200 rounded-lg shadow-lg w-[280px]">
        <div class="px-4 py-3">
          <p className="text-gray-700 text-center font-semibold truncate">🤫 Admin Menu 🤫</p>
        </div>
        <div class="border-b bg-gray-200 w-full"></div>

        <ul className="flex flex-col">
          <li
            class="text-gray-700 bg-error-50 cursor-pointer select-none text-sm px-4 h-10 flex items-center hover:bg-error-100 space-x-2 w-full"
            onClick={() => clearEditorChanges()}
          >
            <NWIcon name="trash" width={16} height={16} />
            <span>Reset editor changes</span>
          </li>
          <div class="border-b bg-gray-200 w-full"></div>
          <li
            class="text-gray-700 bg-error-50 cursor-pointer select-none text-sm px-4 h-10 flex items-center hover:bg-error-100 space-x-2 w-full"
            onClick={() => toggleEditorEditing()}
          >
            <NWIcon name="edit" width={16} height={16} />
            <span>Enable editing</span>
          </li>
        </ul>
      </div>
    );
  }
}
