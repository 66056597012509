import { bind, Component, h, signal, tag } from "omi";

import { DropdownItem, NWDropdown } from "@/components/atoms/nw-dropdown";
import { NWIcon } from "@/components/atoms/nw-icon";
import { DaysPerWeek, HoursPerDay } from "@/models";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

import { useUserPreferencesMutation } from "../../store/mutations";
import { DaysPerWeekDropdownItems, HoursPerDayDropdownItems } from "./models";

type Props = {
  onSettingsUpdate: (hourPerDay: HoursPerDay, daysPerWeek: DaysPerWeek) => void;
};

@tag("nw-directory-roadmap-time-selector")
export class RoadmapTimeSelector extends Component<Props> {
  static css = [tailwind];

  private userPrefsMutation = useUserPreferencesMutation();
  private hoursPerDayItems = signal<DropdownItem[]>([]);
  private daysPerWeekItems = signal<DropdownItem[]>([]);

  install() {
    this.hoursPerDayItems.value = HoursPerDayDropdownItems(
      hours => this.updateTime(hours, Store.user.preferences.daysPerWeek.value),
      value => this.isItemActive(value, "hours"),
    );

    this.daysPerWeekItems.value = DaysPerWeekDropdownItems(
      days => this.updateTime(Store.user.preferences.hoursPerDay.value, days),
      value => this.isItemActive(value, "days"),
    );
  }

  @bind
  private async updateTime(hourPerDay: HoursPerDay, daysPerWeek: DaysPerWeek) {
    this.userPrefsMutation.mutateTime(hourPerDay, daysPerWeek);
    this.props.onSettingsUpdate(hourPerDay, daysPerWeek);

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackTimeSwitcherTimeChanged(hourPerDay, daysPerWeek);
    });
  }

  private isItemActive(value: HoursPerDay | DaysPerWeek, type: "hours" | "days") {
    const currentValue =
      type === "hours" ? Store.user.preferences.hoursPerDay.value : Store.user.preferences.daysPerWeek.value;
    return currentValue === value;
  }

  private renderHoursPerDayText() {
    if (Store.user.preferences.hoursPerDay.value === HoursPerDay.NONE) {
      return <p class="text-nowrap">No schedule</p>;
    }

    return (
      <p class="text-nowrap">
        <b class="mr-1">{Store.user.preferences.hoursPerDay.value}</b> hours p/d
      </p>
    );
  }

  private renderDaysPerWeek() {
    if (Store.user.preferences.daysPerWeek.value === DaysPerWeek.NONE) {
      return <p class="text-nowrap">No schedule</p>;
    }

    return (
      <p class="text-nowrap">
        <b class="mr-1">{Store.user.preferences.daysPerWeek.value}</b> days p/w
      </p>
    );
  }

  render() {
    return (
      <div className="mx-auto">
        <div class="p-2 flex w-full flex-col justify-center rounded-lg space-y-4">
          <NWDropdown items={this.hoursPerDayItems.value} width="full" withChevron>
            <div class="flex py-0.5 px-1 items-center gap-2 text-base">
              <NWIcon name="hourglass" />
              {this.renderHoursPerDayText()}
            </div>
          </NWDropdown>

          <NWDropdown items={this.daysPerWeekItems.value} width="full" withChevron>
            <div class="flex items-center py-0.5 px-1 gap-2 text-base">
              <NWIcon name="calendar" />
              {this.renderDaysPerWeek()}
            </div>
          </NWDropdown>
        </div>
      </div>
    );
  }
}
