import { SignalObject, SignalValue } from "omi";
import includes from "lodash-es/includes";

import { storageSignal } from "@/utils/signals/storageSignal.ts";
import { NetworkSignal, networkSignal } from "@/utils/signals";
import { SearchResult } from "@/api";
import { initApi } from "@/utils";

import { getMetadata } from "../../utils";

export type UseSearch = ReturnType<typeof useSearch>;

const search = networkSignal<SearchResult[]>({ default: [], debounceMs: 200 });
const searchQuery = storageSignal<string>("search-query", { default: "", storageType: "session" });

type Store = {
  searchQuery: string;
};

type Actions = {
  fetch: (searchQuery?: string) => Promise<SearchResult[]>;
  setSearchQuery: (query: string) => void;
  updateSelected: (ids: string[], selected: boolean) => void;
};
/**
 * Custom hook to manage search functionality with abort controller logic.
 */
export function useSearch(): SignalObject<Store> & Actions & NetworkSignal<SearchResult[]>["signal"] {
  /**
   * Fetches search results based on the search query.
   * Cancels any ongoing request before initiating a new one.
   * @param query - The query string to search for.
   */
  const fetch = async (query?: string) => {
    const _query = query || searchQuery.value;
    const res = await search.run(() => getSearch(_query));
    return res || [];
  };

  return {
    ...search.signal,
    searchQuery,

    fetch,
    setSearchQuery: setSearchQueryFunc(searchQuery, fetch),
    updateSelected: updateSelectedFunc(search),
  };
}

/**
 * Performs the search API call.
 * @param searchQuery - The query string to search for.
 * @returns A promise resolving to an array of search results.
 */
async function getSearch(searchQuery: string): Promise<SearchResult[]> {
  const api = initApi();
  const { results } = await api.search({ q: searchQuery });

  return results || [];
}

/**
 * Update the search query and fetch results with debounce.
 */
function setSearchQueryFunc(querySignal: SignalValue<string>, fetch: Actions["fetch"]): (query: string) => void {
  return (query: string) => {
    querySignal.value = query;
    fetch(query);
  };
}

/**
 * Update the selected status of a project in the search results.
 */
function updateSelectedFunc(searchSignal: NetworkSignal<SearchResult[]>): (ids: string[], selected: boolean) => void {
  return (ids: string[], selected: boolean) => {
    const searchResults = searchSignal.signal.data.value || [];

    for (const { entities } of searchResults) {
      for (const entity of entities || []) {
        const metadata = getMetadata(entity);
        if (ids.includes(entity.id)) {
          metadata.selected = selected ? "true" : "false";
        }
      }
    }

    for (const { entities } of searchResults) {
      const projects = entities?.filter(p => includes(ids, p.id));
      if (projects) {
        projects.forEach(p => {
          p.metadata?.forEach(m => {
            if (m.key === "selected") {
              m.value = selected ? "true" : "false";
            }
          });
        });
      }
    }

    searchSignal.signal.data.value = searchResults;
  };
}
