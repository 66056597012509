import { signal } from "omi";
import { includes } from "lodash-es";

import { getSelectedTime, orderAndGroup } from "@/pages/project/features/directory/utils";
import { networkSignal } from "@/utils/signals";
import { Project } from "@/api";
import { initApi } from "@/utils";

export type UseProjectStore = ReturnType<typeof useProjects>;

const projects = networkSignal<Project[]>({ default: [] });
const projectsByCategory = signal<[string, Project[]][]>([]);

export function useProjects() {
  const fetch = async () => {
    const res = await projects.run(getProjects);
    updateCachedSignals(res);
  };

  return {
    fetch,
    updateProjectStatus,
    projects: projects.signal,

    getProject: getProjectSelector(),
    selectProject: selectProjectFunc(true),
    unselectProject: selectProjectFunc(false),
  };
}

async function getProjects(): Promise<Project[]> {
  const api = initApi();
  return await api.getProjects();
}

function selectProjectFunc(updateSelectedTo: boolean): (projectIds: string[]) => void {
  return (projectIds: string[]) => {
    const _projects = projects.signal.data.value?.filter(p => includes(projectIds, p.id));
    if (_projects) {
      _projects.forEach(p => {
        p.selected = updateSelectedTo;
        p.selectedTime = updateSelectedTo ? getSelectedTime(p, true) : undefined;
      });

      projects.signal.data.update();
      updateCachedSignals(projects.signal.data.value);
    }
  };
}

function getProjectSelector(): (id: Project["id"]) => Project | undefined {
  return id => projects.signal.data.value?.find(p => p.id === id);
}

function updateProjectStatus(projectId: string, status: Project["status"]) {
  if (projects.signal.data.value == null) {
    return;
  }

  const allProjects = [...projects.signal.data.value];
  const project = allProjects.find(p => p.id === projectId);
  if (project) {
    project.status = status;
    projects.signal.data.value = allProjects;
    updateCachedSignals(allProjects);
  }
}

function updateCachedSignals(projects: Project[] | undefined) {
  const toCategorise = projects?.filter(p => p.selected) || [];
  projectsByCategory.value = orderAndGroup(toCategorise);
}
