import { Component, h, tag } from "omi";

import { tailwind } from "@/tailwind";

import { NWProjectDirectory } from "../../../directory";

type Props = {};

@tag("nw-toolbar-view-directory")
export class NWToolbarViewDirectory extends Component<Props> {
  static css = [tailwind];

  render() {
    return <NWProjectDirectory />;
  }
}
