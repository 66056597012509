import { h } from "omi";

export function RoadmapSkeleton() {
  return (
    <div class="animate-fadeInFast">
      <div class="animate-pulse space-y-12">
        {[1, 2].map(() => (
          <div class="space-y-2">
            <div class="h-5 bg-gray-200 rounded-md w-1/6"></div>
            <div class="space-y-2">
              <div class="h-10 bg-gray-200 rounded-md w-full"></div>
              <div class="px-4 space-y-2">
                <div class="h-14 bg-gray-200 rounded-md w-full"></div>
                <div class="h-14 bg-gray-200 rounded-md w-full"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
