import { h } from "omi";

import { TOOLBAR_SHOW_EXPLORE } from "@/globals/storageKeys";
import { addToQueryString } from "@/utils/url.ts";
import { NWButton, NWTooltip, openLoginModal } from "@/components";
import { Store } from "@/store";

export function SelectedStatus({
  isSelected,
  onToggle,
}: {
  isSelected: boolean;
  onToggle: (event: MouseEvent, state: boolean) => void;
}) {
  const handleToggle = (event: MouseEvent, state: boolean) => {
    event.preventDefault();
    if (!Store.user.isLoggedIn.value) {
      addToQueryString(TOOLBAR_SHOW_EXPLORE, "true");
      return openLoginModal();
    }

    onToggle(event, state);
  };

  return (
    <NWTooltip
      delay={[400, 0]}
      content={isSelected ? "Remove project" : "Add project"}
      placement="top"
      strategy="absolute"
    >
      <div className="relative">
        <NWButton
          backgroundTransparent
          size="icon"
          type="icon"
          icon={isSelected ? "minus-circle" : "plus-circle"}
          iconSize="lg"
          iconColor={isSelected ? "text-brand-400" : "text-gray-400"}
          onClick={e => handleToggle(e, !isSelected)}
          className={`transition-all animate-fadeInFast duration-300 ease-in-out ${isSelected ? "rotate-180" : "rotate-0"}`}
        />
      </div>
    </NWTooltip>
  );
}
