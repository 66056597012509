import { NetworkSignal, networkSignal } from "@/utils/signals";
import { Project, ProjectUpdate, SubmitProjectUpdateRequest } from "@/api";
import { initApi } from "@/utils";

const projectUpdateSignal = networkSignal<Project | undefined>({
  retry: { maxAttempts: 3, delayMs: 2000, backoffMultiplier: 2 },
});

type UseProjectUpdate = {
  mutate: (projectId: string, options: ProjectUpdate) => Promise<Project | undefined>;
} & Omit<NetworkSignal<Project>, "run">;

/**
 * Hook for updating project details
 * @returns Object containing mutate function to update project
 */
export function useProjectUpdate(): UseProjectUpdate {
  const api = initApi();

  async function updateProject(projectId: string, options: ProjectUpdate): Promise<Project | undefined> {
    const updatePayload: SubmitProjectUpdateRequest = {
      projectId,
      projectUpdate: options,
    };

    return await api.submitProjectUpdate(updatePayload);
  }

  /**
   * Mutate function to trigger project update
   */
  const mutate = async (projectId: string, options: ProjectUpdate) =>
    projectUpdateSignal.run(async () => updateProject(projectId, options));

  return { mutate, ...projectUpdateSignal };
}
