import { bind, classNames, effect, h, signal, tag } from "omi";

import { DirectoryStore } from "@/pages/project/features/directory/store";
import { ONBOARDING_HIDE_BG } from "@/globals/storageKeys.ts";
import { onEscPress } from "@/utils/keys";
import { PureComponent } from "@/utils/pureComponent";
import { getFromQueryString } from "@/utils/url.ts";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

import { NWToolbarViewAdmin, NWToolbarViewDirectory, NWToolbarViewUser, ToolbarView } from ".";

type Props = {};

@tag("nw-toolbar-view-manager")
export class NWToolbarViewManager extends PureComponent<Props> {
  static css = [tailwind];

  private removeEscListener: (() => void) | undefined;
  private removeClickListener: (() => void) | undefined;
  private previousView = signal<ToolbarView | undefined>(undefined);
  private isAnimating = signal<boolean>(false);

  private animationTimingOnboarding = 800;
  private animationTiming = 600;

  private getAnimationTiming() {
    return DirectoryStore.isOnboardingModeActive() && getFromQueryString(ONBOARDING_HIDE_BG, false) !== "true"
      ? this.animationTimingOnboarding
      : this.animationTiming;
  }

  installed() {
    effect(() => {
      const currentView = Store.app.activeToolbarView.value;
      if (currentView !== this.previousView.value) {
        this.isAnimating.value = true;
        setTimeout(() => {
          this.previousView.value = currentView;
          this.isAnimating.value = false;
        }, this.getAnimationTiming());
      }

      if (currentView && !DirectoryStore.isOnboardingModeActive()) {
        this.setupEventListeners();
      } else {
        this.removeEventListeners();
      }
    });
  }

  uninstall(): void {
    this.removeEventListeners();
  }

  private setupEventListeners(): void {
    this.removeEscListener = onEscPress(this.handleClose);
    this.removeClickListener = this.setupOutsideClickListener();
  }

  private removeEventListeners(): void {
    this.removeEscListener?.();
    this.removeClickListener?.();
  }

  private setupOutsideClickListener(): () => void {
    const handleOutsideClick = (event: MouseEvent): void => {
      const path = event.composedPath();
      const target = path[0] as Node;
      const containsToolbar = path.some(element => {
        return element instanceof HTMLElement && element.tagName.toLowerCase() === "nw-toolbar";
      });

      if (!containsToolbar && !this.contains(target)) {
        this.handleClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }

  @bind
  private handleClose(): void {
    Store.app.showToolbarView(undefined);
  }

  render() {
    const activeView = Store.app.activeToolbarView.value;

    return (
      <div class="pointer-events-auto overflow-hidden">
        <div
          class={classNames(
            "transition-all ease-in-out",
            activeView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4",
            {
              "duration-[800ms]": this.getAnimationTiming() === this.animationTimingOnboarding,
              "duration-600": this.getAnimationTiming() === this.animationTiming,
            },
          )}
        >
          {Store.features.hasFeature("projects.navV2") && activeView === "directory" && <NWToolbarViewDirectory />}
          {activeView === "admin" && <NWToolbarViewAdmin />}
          {activeView === "user" && <NWToolbarViewUser />}
        </div>
      </div>
    );
  }
}
