import { h } from "omi";

export function TopicListSkeleton() {
  return (
    <div class="animate-fadeInFast">
      <div class="animate-pulse flex flex-row items-center space-x-2">
        {[1, 2, 3].map(() => (
          <div class="rounded-full flex-1 h-14 m-0.5 bg-gray-100 capitalize whitespace-nowrap"> </div>
        ))}
      </div>
    </div>
  );
}
