import { classNames, Component, h, tag } from "omi";

import { SelectedStatus } from "@/pages/project/features/directory/components/selectButton";
import { Project, Task } from "@/api";
import { tailwind } from "@/tailwind";

import { DirectoryStore } from "../../store";
import { getUrlForProject, isActiveProject } from "../../utils";
import { ProjectStatusIndicator } from "../projectStatusIndicator";

type ProjectProps = {
  type: "project";
  project: Project;
  alwaysShowUnselect?: boolean;
};

type TaskProps = {
  type: "task";
  task: Task;
};

type Props = ProjectProps | TaskProps;

@tag("nw-directory-my-projects-list-item")
export class ListItem extends Component<Props> {
  static css = [tailwind];

  private handleSelection = (id: string, selected: boolean) => {
    DirectoryStore.updateRoadmap([id], selected);
  };

  render(props: Props) {
    const id = props.type === "project" ? props.project.id : props.task.id;
    const title = props.type === "project" ? props.project.metadata?.title : props.task.name;
    const status = props.type === "project" ? props.project.status : props.task.status;
    const category = props.type === "project" ? props.project.metadata?.category : props.task.category;
    const isSelected = props.type === "project" ? props.project.selected : true;
    const alwaysShowUnselect = props.type === "project" ? (props.alwaysShowUnselect ?? true) : false;

    return (
      <div className="flex group flex-row px-3 py-3 justify-between transition-all hover:bg-gray-50 rounded-[20px]">
        <a className="flex flex-col space-y-1 px-3 truncate" href={getUrlForProject(id!)}>
          <h4 className="text-lg text-gray-900 truncate hover:underline hover:underline-offset-2">{title}</h4>
          <div className="flex items-center gap-2  select-none">
            <ProjectStatusIndicator status={status} isActive={isActiveProject(id)} size="lg" />
            <span className="text-sm text-gray-500">{category}</span>
            <span className="text-sm text-gray-500">•</span>
            <span className="text-sm text-gray-500">Project</span>
          </div>
        </a>
        <div
          className={classNames("flex items-center", {
            "group-hover:opacity-100 opacity-0 transition-opacity duration-300 ease-in-out": !alwaysShowUnselect,
          })}
        >
          <SelectedStatus isSelected={!!isSelected} onToggle={() => this.handleSelection(id!, !isSelected)} />
        </div>
      </div>
    );
  }
}
