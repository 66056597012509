import Omi, { bind, Component, h, signal, tag } from "omi";
import classNames from "classnames";

import { tailwind } from "@/tailwind";

/**
 * Represents a single tab item.
 */
type TabItem = {
  id: string;
  label: string | JSX.Element;
  content: JSX.Element;
};

/**
 * Props for the NWTabs component.
 */
type NWTabsProps = {
  tabs: TabItem[];
  activeTabId?: string;
  onTabChange?: (tabId: string) => void;
};

/**
 * NWTabs component for rendering a tabbed interface.
 */
@tag("nw-tabs")
export class NWTabs extends Component<NWTabsProps> {
  static css = [tailwind];
  private activeTab = signal<string | undefined>(undefined);
  private previousTab = signal<string | undefined>(undefined);

  installed(): void {
    this.activeTab.value = this.props.activeTabId || this.props.tabs[0]?.id;
    document.addEventListener("keydown", this.handleKeyDown);
  }

  uninstall(): void {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  receiveProps(props: Omi.OmiProps<NWTabsProps> | NWTabsProps, oldProps: Omi.OmiProps<NWTabsProps> | NWTabsProps) {
    if (props.activeTabId !== oldProps.activeTabId) {
      this.previousTab.value = this.activeTab.value;
      this.activeTab.value = props.activeTabId;
    } else {
      return false;
    }
  }

  /**
   * Handles tab click event.
   * @param tabId - The ID of the clicked tab.
   */
  private handleTabClick = (tabId: string): void => {
    this.changeTab(tabId);
  };

  /**
   * Handles key down events for arrow navigation.
   * @param event - The keyboard event.
   */
  @bind
  private handleKeyDown(event: KeyboardEvent) {
    if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") return;

    const currentIndex = this.props.tabs.findIndex(tab => tab.id === this.activeTab.value);
    let newIndex: number;

    if (event.key === "ArrowLeft") {
      newIndex = (currentIndex - 1 + this.props.tabs.length) % this.props.tabs.length;
    } else {
      newIndex = (currentIndex + 1) % this.props.tabs.length;
    }

    this.changeTab(this.props.tabs[newIndex].id);
  }

  /**
   * Changes the active tab.
   * @param tabId - The ID of the new active tab.
   */
  private changeTab(tabId: string): void {
    if (this.props.onTabChange) {
      this.props.onTabChange(tabId);
    }

    this.previousTab.value = this.activeTab.value;
    this.activeTab.value = tabId;
  }

  /**
   * Renders the tab headers.
   * @returns JSX element for tab headers.
   */
  private renderTabHeaders(tabs: TabItem[]): JSX.Element {
    return (
      <div role="tablist" class="flex space-x-2">
        {tabs.map(tab => (
          <button
            key={tab.id}
            role="tab"
            aria-selected={tab.id === this.activeTab.value}
            aria-controls={`panel-${tab.id}`}
            id={`tab-${tab.id}`}
            tabIndex={0}
            class={classNames(
              "px-3 py-2 text-sm rounded-full font-semibold gap-2 outline-none flex-1 transition-all",
              tab.id === this.activeTab.value
                ? "text-gray-700 bg-gray-100"
                : "text-gray-500 hover:text-gray-700 active:bg-gray-200 active:duration-0 hover:bg-gray-50",
            )}
            onClick={() => this.handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    );
  }

  /**
   * Renders the tab content.
   * @returns JSX element for tab content.
   */
  private renderTabContent(tabs: TabItem[]): JSX.Element {
    const activeIndex = tabs.findIndex(tab => tab.id === this.activeTab.value);

    return (
      <div class="mt-4 overflow-hidden relative">
        <div
          class="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${100 * activeIndex}%)` }}
        >
          {tabs.map(tab => (
            <div
              key={tab.id}
              role="tabpanel"
              id={`panel-${tab.id}`}
              aria-labelledby={`tab-${tab.id}`}
              class="w-full flex-shrink-0 pb-32 max-h-[84svh]"
              tabIndex={-1}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    );
  }

  render({ tabs }: NWTabsProps): JSX.Element {
    return (
      <div class="w-full">
        {this.renderTabHeaders(tabs)}
        {this.renderTabContent(tabs)}
      </div>
    );
  }
}
