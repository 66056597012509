import { Component, createRef, h, OmiProps, signal, tag } from "omi";

import { DirectoryStore } from "@/pages/project/features/directory/store";
import { DirectoryView } from "@/pages/project/features/directory/views";
import { NWButton } from "@/components/atoms/nw-button";
import { NWDropdown } from "@/components/atoms/nw-dropdown";
import { storageSignal } from "@/utils/signals/storageSignal";
import { NWTooltip } from "@/components";
import { DaysPerWeek, HoursPerDay } from "@/models";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

import { useMyProjects } from "../../store/queries/useMyProjects";
import { useSchedule } from "../../store/queries/useSchedule";
import { ProjectList } from "../../components/projectList";
import { RoadmapView } from "../../components/roadmap";
import { OrderBy, SortBy } from "../../store/models";
import { RoadmapSettings } from "./settings";

type Props = {};

const enum ViewBy {
  LIST_VIEW = "List View",
  ROADMAP_VIEW = "Roadmap View",
  ROADMAP_SETTINGS = "Roadmap Settings",
}

@tag("nw-directory-my-projects")
export class NWDirectoryMyProjects extends Component<Props> {
  static css = [tailwind];

  private scrollContainerRef = createRef<HTMLDivElement>();
  private myProjects = useMyProjects();
  private scheduleSlice = useSchedule();

  private sortBy = storageSignal<SortBy>("my_project_sort_by", { default: SortBy.ASC });
  private orderBy = storageSignal<OrderBy>("my_project_order_by", { default: OrderBy.TOPIC });
  private viewBy = signal<ViewBy>(ViewBy.LIST_VIEW);

  install() {
    this.myProjects.fetch();
    this.scheduleSlice.fetch({ sortBy: this.sortBy.value, orderBy: this.orderBy.value });
  }

  installed() {
    this.scrollContainerRef.current?.addEventListener("wheel", this.handleOverscroll);
  }

  uninstall() {
    this.scrollContainerRef.current?.removeEventListener("wheel", this.handleOverscroll);
  }

  private handleOverscroll = (event: WheelEvent) => {
    if (!this.scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollContainerRef.current!;
    if ((event.deltaY < 0 && scrollTop === 0) || (event.deltaY > 0 && scrollTop + clientHeight >= scrollHeight)) {
      event.preventDefault();
    }
  };

  private handleOrderByChange = (orderBy: OrderBy) => {
    if (orderBy === OrderBy.RECENTLY_ADDED) {
      this.sortBy.value = SortBy.ASC;
    }

    this.orderBy.value = orderBy;
    this.scheduleSlice.fetch({ sortBy: this.sortBy.value, orderBy: this.orderBy.value });
    this.trackOrderOrSortChange();
  };

  private handleSortByChange = () => {
    this.sortBy.value = this.sortBy.value === SortBy.ASC ? SortBy.DESC : SortBy.ASC;
    this.scheduleSlice.fetch({ sortBy: this.sortBy.value, orderBy: this.orderBy.value });
    this.trackOrderOrSortChange();
  };

  private trackOrderOrSortChange = () => {
    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackMyProjectsSortChanged(this.sortBy.value, this.orderBy.value);
    });
  };

  private handleViewByChange = (targetView: ViewBy) => {
    this.viewBy.value = targetView;

    if (targetView === ViewBy.ROADMAP_VIEW) {
      import("@/services/analyticsService").then(({ useAnalyticsService }) => {
        useAnalyticsService().trackScheduleViewed();
      });
    }
  };

  private getProjectsByCategory = () => {
    return this.myProjects.getProjectsByCategory(this.sortBy.value, this.orderBy.value);
  };

  private getRoadmap = () => {
    return this.scheduleSlice.getSchedule(this.sortBy.value, this.orderBy.value);
  };

  private navigateToExplore = () => {
    DirectoryStore.show(DirectoryView.EXPLORE);
  };

  private onSettingsUpdate = (hourPerDay: HoursPerDay, daysPerWeek: DaysPerWeek) => {
    this.scheduleSlice.fetch({ hourPerDay, daysPerWeek, orderBy: this.orderBy.value, sortBy: this.sortBy.value });

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackTimeSwitcherTimeChanged(hourPerDay, daysPerWeek);
    });
  };

  private getOrderByDisplay = (orderBy: OrderBy) => {
    switch (orderBy) {
      case OrderBy.RECENTLY_ADDED:
        return "Recently added";
      case OrderBy.TOPIC:
        return "Series (A-Z)";
      case OrderBy.RECOMMENDED:
        return "NextWork recommended";
    }
  };

  render() {
    // Anon users always have 0 selected projects
    const isEmpty = Store.user.isLoggedIn.value ? this.myProjects.data.value?.length === 0 : true;

    return (
      <div className="flex flex-col overflow-y-auto pr-2 h-full space-y-6 pt-1" ref={this.scrollContainerRef}>
        <div className="flex items-center justify-between gap-4">
          <div className="text-xl font-semibold">My Projects</div>
          <NWButton type="secondary" size="md" icon="plus" iconSize="sm" onClick={this.navigateToExplore}>
            Add projects
          </NWButton>
        </div>

        {!isEmpty && (
          <div className="flex justify-between items-center py-3 border-y mb-6 px-1">
            <div className="flex space-x-1 items-center ">
              <NWTooltip
                placement="top"
                strategy="absolute"
                delay={[200, 0]}
                content={this.sortBy.value === SortBy.ASC ? "Order by asc" : "Order by desc"}
              >
                <NWButton
                  type="icon"
                  size="icon"
                  className="text-gray-600"
                  icon={this.sortBy.value === SortBy.ASC ? "sort-arrow-down" : "sort-arrow-up"}
                  iconSize="lg"
                  onClick={this.handleSortByChange}
                />
              </NWTooltip>

              <OrderByDropdown orderBy={this.orderBy.value} onOrderByChange={this.handleOrderByChange}>
                <span className="text-sm font-semibold text-gray-600 uppercase">
                  {this.getOrderByDisplay(this.orderBy.value)}
                </span>
              </OrderByDropdown>
            </div>

            <div className="flex space-x-1 text-gray-600">
              {(this.viewBy.value === ViewBy.ROADMAP_VIEW || this.viewBy.value === ViewBy.ROADMAP_SETTINGS) && (
                <NWTooltip content={"Study schedule settings"} placement="top" strategy="absolute" delay={[200, 0]}>
                  <NWButton
                    onClick={() => this.handleViewByChange(ViewBy.ROADMAP_SETTINGS)}
                    type="icon"
                    size="icon"
                    icon="settings-cog"
                    iconSize="md"
                  />
                </NWTooltip>
              )}
              <NWTooltip
                content={this.viewBy.value === ViewBy.ROADMAP_VIEW ? "List view" : "Roadmap view"}
                placement="top"
                strategy="absolute"
                delay={[200, 0]}
              >
                <NWButton
                  onClick={() =>
                    this.handleViewByChange(
                      this.viewBy.value === ViewBy.ROADMAP_VIEW ? ViewBy.LIST_VIEW : ViewBy.ROADMAP_VIEW,
                    )
                  }
                  type="icon"
                  size="icon"
                  icon={this.viewBy.value === ViewBy.ROADMAP_VIEW ? "list" : "calendar"}
                  iconSize="md"
                />
              </NWTooltip>
            </div>
          </div>
        )}

        <div className="flex-1">
          {this.viewBy.value === ViewBy.ROADMAP_VIEW && (
            <RoadmapView
              roadmapData={this.getRoadmap()}
              isLoading={this.scheduleSlice.isLoading.value}
              error={this.scheduleSlice.error.value}
            />
          )}
          {this.viewBy.value === ViewBy.LIST_VIEW && (
            <ProjectList
              type="grouped"
              projectsByCategory={this.getProjectsByCategory()}
              isLoading={this.myProjects.isLoading.value}
              error={this.myProjects.error.value}
              orderBy={this.orderBy.value}
            />
          )}
          {this.viewBy.value === ViewBy.ROADMAP_SETTINGS && (
            <RoadmapSettings onSettingsUpdate={this.onSettingsUpdate} />
          )}
        </div>
      </div>
    );
  }
}

function OrderByDropdown({
  orderBy,
  onOrderByChange,
  children,
}: OmiProps<{ orderBy: OrderBy; onOrderByChange: (orderBy: OrderBy) => void }>) {
  return (
    <NWDropdown
      withBorder={false}
      width="fit"
      items={[
        {
          type: "button",
          text: "Recently added",
          isActive: () => orderBy === OrderBy.RECENTLY_ADDED,
          callback: () => {
            onOrderByChange(OrderBy.RECENTLY_ADDED);
          },
        },
        {
          type: "button",
          text: "Series (A-Z)",
          isActive: () => orderBy === OrderBy.TOPIC,
          callback: () => {
            onOrderByChange(OrderBy.TOPIC);
          },
        },
        {
          type: "button",
          text: "NextWork recommended",
          isActive: () => orderBy === OrderBy.RECOMMENDED,
          callback: () => {
            onOrderByChange(OrderBy.RECOMMENDED);
          },
        },
      ]}
    >
      {children}
    </NWDropdown>
  );
}
