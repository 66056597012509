import { Component, h, tag } from "omi";

import { NWGraphic } from "@/components/atoms/nw-graphic";
import { COMMUNITY_LINK } from "@/globals/links";
import { DaysPerWeek, HoursPerDay } from "@/models";
import { tailwind } from "@/tailwind";

import { NWIcon } from "../../../../../../components/atoms/nw-icon/index";
import { RoadmapTimeSelector } from "../../components/roadmapTimeSelector";

type Props = {
  onSettingsUpdate: (hourPerDay: HoursPerDay, daysPerWeek: DaysPerWeek) => void;
};

@tag("nw-directory-my-projects-settings")
export class RoadmapSettings extends Component<Props> {
  static css = [tailwind];

  render(props: Props) {
    return (
      <div class="flex flex-col items-center justify-center p-4">
        <div class="bg-white rounded-lg p-6 w-full space-y-8">
          <div className="space-y-4 w-3/4 mx-auto">
            <div class="flex justify-center">
              <NWGraphic name="lightbulb" mode="static" class="mx-auto w-fit text-gray-400" />
            </div>

            <h2 class="text-gray-900 text-center mb-6">Build great study habits with a regular study schedule.</h2>
          </div>

          <div class="max-w-sm mx-auto">
            <RoadmapTimeSelector onSettingsUpdate={props.onSettingsUpdate} />
          </div>

          <div className="space-y-4">
            <p class="text-center text-gray-600 text-sm mb-4">Not sure what schedule is best for you?</p>

            <a
              href={COMMUNITY_LINK}
              target="_blank"
              class="flex w-fit mx-auto items-center justify-center text-sm font-semibold text-brand-700 hover:text-brand-800 relative group"
            >
              <NWIcon name="message-question-circle" class="mr-1" />
              Ask in the NextWork community!
            </a>
          </div>
        </div>
      </div>
    );
  }
}
