import { Component, createRef, h, OmiProps, tag } from "omi";

import { NWIcon } from "@/components";
import { tailwind } from "@/tailwind";

type Props = {
  onChange: (value: string) => void;
  query: string;
  focusOnInstall?: boolean;
};

@tag("nw-directory-my-projects-search-bar")
export class DirectorySearchBar extends Component<Props> {
  static css = [tailwind];

  private inputRef = createRef<HTMLInputElement>();

  uninstall(): void {
    this.inputRef.current = undefined;
  }

  installed() {
    if (this.props.focusOnInstall) {
      this.focusInput();
    }
  }

  private onChange = (e: Event): void => {
    const value = (e.target as HTMLInputElement).value;
    this.props.onChange(value);
    this.focusInput();
  };

  private focusInput = (): void => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  receiveProps(props: Props | OmiProps<Props>, oldProps: Props | OmiProps<Props>) {
    if (props.query !== oldProps.query) {
      this.focusInput();
    }
  }

  /**
   * Prevents keyboard events from bubbling up to parent elements
   * when the input is focused
   */
  private onKeyDown = (e: KeyboardEvent): void => {
    e.stopPropagation();
  };

  render({ query }: Props) {
    return (
      <div className="relative flex items-center">
        <div className="absolute inset-y-0 left-0 top-1/2 -translate-y-1/2 flex items-center pointer-events-none">
          <NWIcon name="search" width={28} height={28} className="text-gray-500" tabIndex={-1} />
        </div>

        <input
          ref={this.inputRef}
          tabIndex={1}
          type="text"
          className="w-full pr-4 py-2.5 pl-9 text-lg text-gray-900 border-b border-gray-300 focus:outline-none focus:border-b-brand-400 transition-[border] placeholder:text-muted-foreground disabled:cursor-not-allowed"
          placeholder="AWS topics I want to learn... "
          aria-label="AWS topics I want to learn... "
          onInputCapture={this.onChange}
          onKeyDownCapture={this.onKeyDown}
          value={query}
        />
      </div>
    );
  }
}
