import { DropdownItem, openModal } from "@/components";
import { DaysPerWeek, HoursPerDay } from "@/models";
import { Store } from "@/store";

const ProDays = [DaysPerWeek.ONE, DaysPerWeek.TWO, DaysPerWeek.THREE];

export const HoursPerDayDropdownItems = (
  onChange: (hoursPerDay: HoursPerDay) => void,
  isActive: (value: HoursPerDay) => boolean,
): DropdownItem[] => {
  return Object.values(HoursPerDay)
    .filter(value => typeof value === "number")
    .map(value => ({
      type: "button",
      text: getDisplayTextHour(value as HoursPerDay),
      callback: () => {
        if (!Store.user.isLoggedIn.value) {
          openModal("login", {});
        } else {
          onChange(value as HoursPerDay);
        }
      },
      isActive: () => isActive(value as HoursPerDay),
    }));
};

export const DaysPerWeekDropdownItems = (
  onChange: (daysPerWeek: DaysPerWeek) => void,
  isActive: (value: DaysPerWeek) => boolean,
): DropdownItem[] =>
  Object.values(DaysPerWeek)
    .filter(value => typeof value === "number")
    .map(value => ({
      type: "button",
      text: getDisplayTextDay(value as DaysPerWeek),
      callback: () => {
        if (!Store.user.isLoggedIn.value) {
          openModal("login", {});
        } else {
          onChange(value as DaysPerWeek);
        }
      },
      isActive: () => isActive(value as DaysPerWeek),
      isProOption: ProDays.includes(value as DaysPerWeek),
    }));

export function getDisplayTextHour(value: HoursPerDay) {
  if (value === HoursPerDay.NONE) {
    return "No schedule";
  }

  if (value === HoursPerDay.ONE) {
    return "1 hour a day";
  }

  return `${value} hours a day`;
}

export function getDisplayTextDay(value: DaysPerWeek) {
  if (value === DaysPerWeek.NONE) {
    return "No schedule";
  }

  if (value === DaysPerWeek.ONE) {
    return "1 day a week";
  }

  return `${value} days a week`;
}
