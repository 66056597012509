import { classNames, Component, h, OmiProps, tag } from "omi";

import { DirectoryStore } from "@/pages/project/features/directory/store";
import { NWError } from "@/components/base/nw-error";
import { tailwind } from "@/tailwind";

import { ALL_CATEGORY, isCourse, Topic } from "../../store/models";
import { TopicListSkeleton } from "./skeleton";

type Props = {
  topics: Topic[];
  isLoading: boolean;
  error: unknown | undefined;
  onSelect: (topic: Topic) => void;
};

@tag("nw-directory-topic-list")
export class TopicList extends Component<Props> {
  static css = [tailwind];

  installed() {
    if (DirectoryStore.isOnboardingModeActive()) {
      DirectoryStore.recommendedExpanded.value = false;
    }
  }

  private onSelect = (topic: Topic) => {
    this.props.onSelect(topic);
  };

  private toggleExpand = () => {
    DirectoryStore.recommendedExpanded.value = !DirectoryStore.recommendedExpanded.value;
  };

  private getTopicTitle = (topic: Topic) => {
    return isCourse(topic) ? topic.value.metadata?.title || topic.value.id || "" : topic.value;
  };

  render({ topics = [], isLoading, error }: Props) {
    const expanded = DirectoryStore.isRecommendedExpanded();
    const topicsToShow = expanded ? topics : topics.slice(0, 2);

    if (isLoading) {
      return <TopicListSkeleton />;
    }

    if (error != null) {
      return (
        <div class="animate-fadeInFast">
          <NWError />
        </div>
      );
    }

    return (
      <div
        className={classNames("flex items-center gap-2 self-stretch flex-col sm:flex-row", {
          "flex-wrap justify-center": expanded,
          "overflow-hidden": !expanded,
        })}
      >
        <TopicButton
          fitWidth
          onClick={() => this.onSelect({ id: ALL_CATEGORY, type: "category", value: ALL_CATEGORY })}
        >
          All
        </TopicButton>

        {topicsToShow.map(topic => (
          <TopicButton topic={topic} onClick={() => this.onSelect(topic)}>
            {this.getTopicTitle(topic)}
          </TopicButton>
        ))}

        <TopicButton fitWidth onClick={this.toggleExpand}>
          {expanded ? "Less" : "More"}
        </TopicButton>
      </div>
    );
  }
}

function TopicButton({ onClick, children, fitWidth }: OmiProps<{ fitWidth?: boolean; onClick: () => void }>) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "rounded-full w-fit py-2 px-4 sm:py-4 sm:px-8 m-0.5 bg-gray-50 hover:bg-gray-100 text-gray-700 capitalize whitespace-nowrap",
        { "w-fit": fitWidth, "w-full flex-1": !fitWidth },
      )}
    >
      {children}
    </button>
  );
}
