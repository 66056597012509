import { triggerToast } from "@/components/base/nw-toast";
import { ErrorService } from "@/services/errorService";

const NFT_STORAGE_KEY = "nft-ls-d";

export function triggerNewFeatureToast() {
  try {
    const nftDate = localStorage.getItem(NFT_STORAGE_KEY);

    if (nftDate) {
      const today = new Date().toDateString();
      const triggerDate = new Date(nftDate).toDateString();

      if (today === triggerDate) {
        localStorage.removeItem(NFT_STORAGE_KEY);

        triggerToast({
          title: "🎉 Exciting news, a new feature is ready!",
          message: `Refresh your page to roll out the new feature and enhance your learning experience.`,
          dismissType: "manual",
          withGraphic: true,
          action: {
            label: "Refresh page",
            onClick: () => {
              window.location.reload();
            },
          },
        });
      }
    } else {
      const randomDays = Math.floor(Math.random() * 6) + 2;
      const triggerDate = new Date(new Date().getTime() + randomDays * 24 * 60 * 60 * 1000);
      localStorage.setItem(NFT_STORAGE_KEY, triggerDate.toISOString());
    }
  } catch (error) {
    ErrorService.report(error);
    console.error("Error while triggering new feature toast");
  }
}
