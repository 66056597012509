import { classNames, Component, h, signal, tag } from "omi";

import { NWToolbar } from "@/pages/project/features/toolbar";
import { ONBOARDING_HIDE_BG } from "@/globals/storageKeys";
import { getFromQueryString } from "@/utils/url";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

import { LearnBackground } from "./background";

type Props = {};

@tag("nw-project-features-onboarding")
export class PagesOnboarding extends Component<Props> {
  static css = [tailwind];
  private shouldShowToolbar = signal<boolean>(false);
  private showBackground = signal<boolean>(true);
  private hideBgTriggered = false;

  install() {
    if (getFromQueryString(ONBOARDING_HIDE_BG, false) === "true") {
      this.showBackground.value = false;
      this.hideBgTriggered = true;
    }

    if (this.showBackground.value) {
      this.disableScroll();
    }
  }

  installed() {
    this.triggerEntryAnimationFlow();
  }

  uninstall(): void {
    // Ensure we re-enable scrolling when component is unmounted
    this.enableScroll();
  }

  private disableScroll(): void {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.height = "100%";
  }

  private enableScroll(): void {
    document.body.style.overflow = "";
    document.body.style.position = "";
    document.body.style.width = "";
    document.body.style.height = "";
  }

  private triggerEntryAnimationFlow() {
    if (this.hideBgTriggered) {
      return;
    }

    setTimeout(() => {
      this.shouldShowToolbar.value = true;
      setTimeout(() => {
        Store.app.showToolbarView("directory");
      }, 1000);
    }, 1200);
  }

  render() {
    return (
      <>
        <div
          className={classNames("fixed inset-0 z-50 overflow-hidden", {
            "bg-white": this.showBackground.value,
            hidden: this.hideBgTriggered,
          })}
          onScrollCapture={(e: Event) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onTouchMove={(e: Event) => {
            if (this.showBackground.value) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onWheel={(e: Event) => {
            if (this.showBackground.value) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {this.showBackground.value && <LearnBackground />}
        </div>
        <div className="">
          <NWToolbar
            type="controlled"
            withBackground={this.hideBgTriggered}
            show={this.shouldShowToolbar.value || this.hideBgTriggered}
          />
        </div>
      </>
    );
  }
}
