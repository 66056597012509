import { classNames, Component, h, signal, tag } from "omi";
import every from "lodash-es/every";

import { useProjects } from "@/pages/project/features/directory/store/queries/useProjects";
import { useSearch } from "@/pages/project/features/directory/store/queries/useSearch";
import { storageSignal } from "@/utils/signals/storageSignal";
import { ONBOARDING_HIDE_BG } from "@/globals/storageKeys";
import { Plan, ProjectStatus, SearchResult, SearchResultEntity } from "@/api";
import { NWProBadge, openModal } from "@/components";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

import { DirectoryStore } from "../../store";
import { getMetadata, getUrlForProject, isActiveProject } from "../../utils";
import { ProjectStatusIndicator } from "../projectStatusIndicator";
import { SelectedStatus } from "../selectButton";

export type ProjectSearchResultMetadata = {
  status: ProjectStatus;
  category: string;
  selected: string;
  selectedTime: string | undefined;
  plan: Plan;
};

type ProjectProps = {
  type: "project";
} & SearchResult;

type CourseProps = {
  type: "course";
  id: string;
} & SearchResult;

function isCourse(props: Props): props is CourseProps {
  return props.type === "course";
}

type Props = { type: "project" | "course" } & SearchResult;

@tag("nw-directory-search-list-item")
export class ListItem extends Component<Props> {
  static css = [tailwind];

  private isSelected = signal<boolean>(false);
  private isExpanded = signal<boolean>(false);

  install() {
    if (isCourse(this.props)) {
      this.isExpanded = storageSignal(this.getSearchExpandKey(this.props.id), {
        default: false,
        storageType: "session",
      });
    }
  }

  receiveProps(props: Props) {
    this.isSelected.value = every(props.entities, e => getMetadata(e).selected === "true");
  }

  private getSearchExpandKey(resultId: string): string {
    return `search_expand_${resultId}`;
  }

  private handleSelection = (event: MouseEvent, props: Props, entities: SearchResultEntity[], selected: boolean) => {
    event.preventDefault();

    if (Store.user.isLoggedIn.value === false) {
      return openModal("login", {});
    }

    this.isSelected.value = selected;
    const ids = isCourse(props) ? entities.map(e => e.id) : [entities[0].id];

    DirectoryStore.updateRoadmap(ids, selected, () => {
      this.isSelected.value = !selected;
    });
  };

  private handleCategoryClick = (event: MouseEvent, query: string | undefined) => {
    event.preventDefault();

    if (!query) return;
    useSearch().setSearchQuery(query);
  };

  renderCourse(props: Props) {
    const title = props.title;
    const description = props.description;
    const isInRoadmap = every(props.entities, e => getMetadata(e).selected === "true");
    const isComplete = every(props.entities, e => getMetadata(e).status === ProjectStatus.PROJECT_COMPLETE);
    const projectCount = props.entities?.length || 0;

    return (
      <div
        className={
          "animate-fadeInFast flex group flex-col px-3 py-3 transition-all hover:bg-gray-50 rounded-[20px] divide-y space-y-3"
        }
      >
        <div class={classNames("flex flex-row justify-between")}>
          <div
            tabIndex={1}
            className="flex flex-col justify-start flex-1 space-y-1 truncate px-3"
            onClick={() => (this.isExpanded.value = !this.isExpanded.value)}
          >
            <button className="text-lg text-gray-900 cursor-pointer text-left truncate hover:underline hover:underline-offset-2 w-full">
              {title}
            </button>
            <div className="flex items-center gap-2 select-none">
              <ProjectStatusIndicator
                status={isComplete ? ProjectStatus.PROJECT_COMPLETE : ProjectStatus.PROJECT_INCOMPLETE}
                isActive={false}
                size="lg"
              />
              <span className="text-sm text-gray-500">Series</span>
              {projectCount > 0 && (
                <>
                  <span className="text-sm text-gray-500">•</span>
                  <span className="text-sm text-gray-500">
                    {projectCount}
                    {projectCount === 1 ? " project" : " projects"}
                  </span>
                </>
              )}
            </div>
          </div>

          <div
            className={classNames("flex items-center", {
              "group-hover:opacity-100 opacity-0 transition-opacity duration-300 ease-in-out": !isInRoadmap,
            })}
          >
            <SelectedStatus
              isSelected={isInRoadmap}
              onToggle={e => this.handleSelection(e, props, props.entities!, !isInRoadmap)}
            />
          </div>
        </div>
        <div
          className={classNames("pt-3 space-y-2 overflow-hidden transition-all duration-300 ease-in-out", {
            "h-0 opacity-0 hidden": !this.isExpanded.value,
            "h-auto opacity-100 px-3": this.isExpanded.value,
          })}
        >
          <p class="text-sm text-gray-500 ">{description}</p>

          <div class="flex flex-col gap-2">
            {props.entities?.map(entity => this.renderProject(this.getProjectProps(entity), true))}
          </div>
        </div>
      </div>
    );
  }

  private getProjectProps(entity: SearchResultEntity): ProjectProps {
    return {
      ...entity,
      type: "project",
      description: "",
      title: useProjects().getProject(entity.id)?.metadata?.title || "",
      entities: [entity],
    };
  }

  private createProjectUrl(id: string) {
    if (Store.user.isLoggedIn.value) {
      return getUrlForProject(id);
    }

    return getUrlForProject(id, { [ONBOARDING_HIDE_BG]: "true" });
  }

  renderProject(props: ProjectProps, embedded: boolean) {
    const project = props.entities![0];
    const id = project.id;
    const title = props.title;
    const metadata = getMetadata(project);

    const status = metadata.status;
    const category = metadata.category;
    const isProProject = metadata.plan === Plan.PRO;

    const itemType = "Project";
    const isSelected = metadata.selected === "true" || this.isSelected.value;

    return (
      <div
        className={classNames(
          "animate-fadeInFast group flex flex-row py-3 justify-between transition-all hover:bg-gray-50 rounded-[20px]",
          { "px-3": !embedded },
        )}
      >
        <div className="flex flex-col space-y-1 px-3 w-fit truncate">
          <a
            href={this.createProjectUrl(id!)}
            className={"text-lg text-gray-900 truncate hover:underline hover:underline-offset-2"}
          >
            {title}
          </a>
          <div className="flex items-center gap-2  select-none">
            <ProjectStatusIndicator status={status} isActive={isActiveProject(id)} size="lg" />
            <button
              className="inline text-sm text-gray-500 hover:underline hover:underline-offset-2"
              onClick={event => this.handleCategoryClick(event, category)}
            >
              {category}
            </button>
            <span className="text-sm text-gray-500">•</span>
            <span className="text-sm text-gray-500">{itemType}</span>
            {isProProject && (
              <>
                <span className="text-sm text-gray-500">•</span>
                <span className="text-sm text-gray-500">
                  <NWProBadge />
                </span>
              </>
            )}
          </div>
        </div>

        <div
          className={classNames("flex items-center", "group-hover:opacity-100", {
            "opacity-0 transition-opacity duration-300 ease-in-out": !isSelected,
          })}
        >
          <SelectedStatus
            isSelected={isSelected}
            onToggle={e => this.handleSelection(e, props, props.entities!, !isSelected)}
          />
        </div>
      </div>
    );
  }

  render(props: Props) {
    if (isCourse(props)) {
      return this.renderCourse(props);
    }

    return this.renderProject(props as ProjectProps, false);
  }
}
