import { Topic } from "@/pages/project/features/directory/store/models";
import { networkSignal } from "@/utils/signals";

const recommendedTopics = networkSignal<Topic[]>({ default: [] });

export type UseRecommendedTopicsStore = ReturnType<typeof useRecommendedTopics>;

export function useRecommendedTopics() {
  const fetch = async () => {
    return await recommendedTopics.run(getRecommendedTopics);
  };

  return { ...recommendedTopics.signal, fetch };
}

async function getRecommendedTopics(): Promise<Topic[]> {
  const topics = await fetch("/topics/recommended", { headers: { "Content-Type": "application/json" } });
  return await topics.json();
}
