import { Component, h, tag } from "omi";
import isEmpty from "lodash-es/isEmpty";
import some from "lodash-es/some";

import { NWAccordion } from "@/components/atoms/nw-accordion";
import { Schedule, Task } from "@/api";
import { NWButton, NWError } from "@/components";
import { tailwind } from "@/tailwind";

import { ProjectStatusIndicator } from "../../components/projectStatusIndicator";
import { DirectoryStore } from "../../store";
import { isActiveProject } from "../../utils";
import { DirectoryView } from "../../views";
import { ListItem } from "../projectList/listItem";
import { RoadmapSkeleton } from "./skeleton";

type Props = {
  roadmapData: Schedule | undefined;
  isLoading: boolean;
  error: unknown | undefined;
};

@tag("nw-directory-my-projects-roadmap")
export class RoadmapView extends Component<Props> {
  static css = [tailwind];

  private navigateToExplore = () => {
    DirectoryStore.show(DirectoryView.EXPLORE);
  };

  render({ isLoading, roadmapData, error }: Props) {
    if (isLoading) {
      return (
        <div class="animate-fadeInFast">
          <RoadmapSkeleton />
        </div>
      );
    }

    if (isEmpty(roadmapData?.weeks)) {
      return (
        <div className="h-full w-full py-40">
          <div className="flex flex-col items-center justify-center space-y-4">
            <NWButton
              size="icon"
              type="icon"
              icon="plus"
              iconColor="text-gray-700"
              iconOnly
              iconBorder
              onClick={this.navigateToExplore}
            />

            <div className="space-y-1">
              <div className="text-lg font-semibold text-center text-gray-900">Let's start learning</div>
              <div className="text-sm text-gray-500">Add projects to get started!</div>
            </div>
          </div>
        </div>
      );
    }

    if (error != null) {
      return (
        <div class="animate-fadeInFast">
          <NWError />
        </div>
      );
    }

    return (
      <div class="animate-fadeInFast">
        {roadmapData?.weeks.map(({ id, days }) => (
          <div key={id} class="border-b border-gray-200 mb-6 pb-6 last:mb-0 last:pb-0 last:border-0 space-y-2">
            <h4 className="text-xs font-semibold -tracking-tight uppercase text-gray-600 leading-6">Week {id}</h4>

            <div className="flex flex-col gap-2">
              {days.map(({ id, tasks, status }) => (
                <NWAccordion
                  openByDefault={isDayActive(tasks) || hasOnlyOneTask(roadmapData)}
                  useSlotForLabel
                  fullWidth
                >
                  <div slot="label" class="flex items-center space-x-3">
                    <ProjectStatusIndicator status={status} isActive={isDayActive(tasks)} size="lg" />
                    <p>Day {id}</p>
                  </div>

                  {tasks?.map(task => <ListItem type="task" task={task} />)}
                </NWAccordion>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

function isDayActive(tasks: Task[]) {
  return some(tasks, ({ id }) => isActiveProject(id));
}

function hasOnlyOneTask(schedule: Schedule) {
  return (
    schedule.weeks.length === 1 && schedule.weeks[0].days.length === 1 && schedule.weeks[0].days[0].tasks.length === 1
  );
}
