import groupBy from "lodash-es/groupBy";
import orderBy from "lodash-es/orderBy";
import set from "lodash-es/set";
import some from "lodash-es/some";

import { getFromQueryString } from "@/utils/url";
import { Project, SearchResultEntity, Track } from "@/api";
import { Store } from "@/store";

import { ProjectSearchResultMetadata } from "../components/searchList/listItem";
import { useProjects } from "../store/queries/useProjects";

export function isActiveProject(projectId: Project["id"]) {
  return projectId ? projectId === Store.project.id.value : false;
}

export function getOrder(project: Project): { seriesOrder: string; projectOrder: string | undefined } | undefined {
  if (project.metadata?.order != null) {
    const [seriesOrder, projectOrder] = project.metadata?.order?.split(".") as [string, string];
    return { seriesOrder, projectOrder };
  }

  return undefined;
}

export function orderAndGroup(projects: Project[]): [string, Project[]][] {
  const selectedProjects = projects.filter(p => p.selected);
  const groupByCategory = groupBy(selectedProjects, p => p.metadata?.category || "Misc");
  const orderedByCategory = orderBy(Object.entries(groupByCategory), ([category]) => category.toLowerCase());
  return orderedByCategory.map(([category, projects]) => [
    category,
    orderBy(projects, (p, idx) => [getOrder(p)?.seriesOrder || idx, getOrder(p)?.projectOrder || idx]),
  ]);
}

export function getUrlForProject(id: string, additionalQs: { [key: string]: string } = {}): string {
  const base = `/projects/${id}`;
  const track = getFromQueryString("track");
  const params = new URLSearchParams();

  Object.entries(additionalQs).forEach(([key, value]) => {
    params.append(key, value);
  });

  if (track) {
    const nextProject = useProjects().getProject(id);

    if (track === "no") {
      if (nextProject && hasTrack(track, nextProject)) {
        params.append("track", track);
      } else {
        params.append("track", "low");
      }
    } else if (nextProject && hasTrack(track, nextProject)) {
      params.append("track", track);
    }
  }

  const queryString = params.toString();
  return queryString ? `${base}?${queryString}` : base;
}

function hasTrack(trackId: Track["id"], project: Project): boolean {
  return some(project.metadata?.tracks, t => t.id === trackId);
}

export function getSelectedTime(project: Project, forceUpdate: boolean = false): string {
  const newTime = new Date().toISOString();

  if (forceUpdate) {
    return newTime;
  }
  return project.selectedTime || newTime;
}

export function getMetadata(entity: SearchResultEntity): Partial<ProjectSearchResultMetadata> {
  const metadata = entity.metadata?.reduce((acc, { key, value }) => {
    if (key == null) {
      return acc;
    }

    set(acc, key, value);
    return acc;
  }, {} as ProjectSearchResultMetadata);

  if (!metadata) {
    return {} as Partial<ProjectSearchResultMetadata>;
  }

  return metadata;
}
