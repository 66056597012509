/**
 * This service is responsible for deciding whether the users should see a hint or not.
 * It's used to guide the users through the app and to help them understand the different features.
 *
 * It uses localStorage to store the seen status of the hints.
 * If localStorage is not available either due to browser support or permissions, it will not show any hints.
 */

import { getFromLocalStorage, hasLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "@/utils/localStorage";

const HINT_STORAGE_PREFIX = "nw_hint_";

export type UserHint = "portfolio_drag";

export class UserHintService {
  constructor(private readonly hasStorage = hasLocalStorage()) {}

  /**
   * Decides whether the users should see a hint or not.
   * @param hint - The hint to check
   * @returns boolean indicating if the hint should be shown
   */
  shouldSeeHint(hint: UserHint): boolean {
    if (!this.hasStorage) {
      return false;
    }

    const hintKey = `${HINT_STORAGE_PREFIX}${hint}`;
    const hintSeen = getFromLocalStorage<boolean>(hintKey);
    return !hintSeen;
  }

  /**
   * Marks a hint as seen so it won't be shown again
   * @param hint - The hint to mark as seen
   */
  markHintAsSeen(hint: UserHint): void {
    if (!this.hasStorage) {
      return;
    }

    const hintKey = `${HINT_STORAGE_PREFIX}${hint}`;
    saveToLocalStorage(hintKey, true);
  }

  /**
   * Resets a specific hint so it will be shown again
   * @param hint - The hint to reset
   */
  resetHint(hint: UserHint): void {
    if (!this.hasStorage) {
      return;
    }

    const hintKey = `${HINT_STORAGE_PREFIX}${hint}`;
    removeFromLocalStorage(hintKey);
  }

  /**
   * Gets the seen status of a hint
   * @param hint - The hint to check
   * @returns boolean indicating if the hint has been seen
   */
  hasSeenHint(hint: UserHint): boolean {
    if (!this.hasStorage) {
      return false;
    }

    const hintKey = `${HINT_STORAGE_PREFIX}${hint}`;
    return getFromLocalStorage<boolean>(hintKey) ?? false;
  }
}
