import { bind, Component, createRef, h, tag } from "omi";
import classNames from "classnames";
import isEmpty from "lodash-es/isEmpty";

import { DirectoryStore } from "@/pages/project/features/directory/store";
import { NWError } from "@/components/base/nw-error";
import { tailwind } from "@/tailwind";

import { useRecommendedTopics } from "../../store/queries/useRecommendedTopics";
import { useSearch } from "../../store/queries/useSearch";
import { DirectorySearchBar } from "../../components/searchBar";
import { SearchList } from "../../components/searchList";
import { TopicList } from "../../components/topicList";
import { Topic } from "../../store/models";

type Props = {};

@tag("nw-directory-explore")
export class NWDirectoryExploreView extends Component<Props> {
  static css = [tailwind];

  private recommendedTopics = useRecommendedTopics();
  private search = useSearch();
  private scrollContainerRef = createRef<HTMLDivElement>();

  install() {
    this.recommendedTopics.fetch();
  }

  installed() {
    this.scrollContainerRef.current?.addEventListener("wheel", this.handleOverscroll);
  }

  uninstall() {
    this.scrollContainerRef.current?.removeEventListener("wheel", this.handleOverscroll);
  }

  @bind
  private handleOverscroll(event: WheelEvent) {
    if (!this.scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollContainerRef.current!;
    if ((event.deltaY < 0 && scrollTop === 0) || (event.deltaY > 0 && scrollTop + clientHeight >= scrollHeight)) {
      event.preventDefault();
    }
  }

  private onSelectTopic = (topic: Topic) => {
    const title = topic.type === "course" ? topic.value.metadata?.title : topic.value;
    this.search.setSearchQuery(title!);
  };

  private onSearch = (query: string) => {
    this.search.setSearchQuery(query);

    setTimeout(() => {
      if (this.scrollContainerRef.current) {
        this.scrollContainerRef.current.scrollTop = 0;
      }
    }, 0);
  };

  renderOnboardingView() {
    const allError = this.recommendedTopics.error.value && this.search.error.value;
    const hasSearchTerm = !isEmpty(this.search.searchQuery.value);

    return (
      <div className="flex flex-col space-y-10 overflow-y-auto pb-6 pr-2 h-full" ref={this.scrollContainerRef}>
        <div class="sticky top-0 z-10">
          <DirectorySearchBar
            query={this.search.searchQuery.value}
            onChange={this.search.setSearchQuery}
            focusOnInstall={true}
          />
        </div>

        {allError && (
          <div className="animate-fadeInFast">
            <NWError />
          </div>
        )}

        {!allError && (
          <>
            <div className={classNames("flex flex-col space-y-4 animate-fadeInFast", { hidden: hasSearchTerm })}>
              <div className="text-xs font-semibold -tracking-tight uppercase text-gray-600 leading-6">
                Recommended Searches
              </div>
              <TopicList
                topics={this.recommendedTopics.data.value || []}
                onSelect={this.onSelectTopic}
                isLoading={this.recommendedTopics.isLoading.value}
                error={this.recommendedTopics.error.value}
              />
            </div>

            {hasSearchTerm && (
              <div className="animate-fadeInFast">
                <SearchList
                  results={this.search.data.value || []}
                  isLoading={this.search.isLoading.value}
                  error={this.search.error.value}
                  hasSearchTerm={true}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  render() {
    const allError = this.recommendedTopics.error.value && this.search.error.value;
    const hasSearchTerm = !isEmpty(this.search.searchQuery.value);

    if (DirectoryStore.isOnboardingModeActive()) {
      return this.renderOnboardingView();
    }

    return (
      <div className="flex flex-col space-y-10 mt-6 overflow-y-auto pr-2 h-full" ref={this.scrollContainerRef}>
        <div class="sticky top-0 z-10">
          <DirectorySearchBar query={this.search.searchQuery.value} onChange={this.onSearch} />
        </div>

        {allError && (
          <div className="animate-fadeInFast">
            <NWError />
          </div>
        )}

        {!allError && (
          <>
            <div className={classNames("flex flex-col space-y-4 animate-fadeInFast", { hidden: hasSearchTerm })}>
              <div className="text-xs font-semibold -tracking-tight uppercase text-gray-600 leading-6">
                Recommended Searches
              </div>
              <TopicList
                topics={this.recommendedTopics.data.value || []}
                onSelect={this.onSelectTopic}
                isLoading={this.recommendedTopics.isLoading.value}
                error={this.recommendedTopics.error.value}
              />
            </div>

            <div className="flex flex-col space-y-4">
              <SearchList
                results={this.search.data.value || []}
                isLoading={this.search.isLoading.value}
                error={this.search.error.value}
                hasSearchTerm={hasSearchTerm}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
