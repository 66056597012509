import { SignalObject, signalObject } from "omi";

import { Project } from "@/api";
import { openModal } from "@/components";
import { Store as GlobalStore } from "@/store";

import { DirectoryView } from "../views";
import { useMyProjects } from "./queries/useMyProjects";
import { useProjects } from "./queries/useProjects";
import { useSchedule } from "./queries/useSchedule";
import { useSearch } from "./queries/useSearch";
import { useUpdateSelectedProjectsMutation } from "./mutations";

type Store = {
  activeView: DirectoryView;
  onboardingModeActive: boolean;
  recommendedExpanded: boolean;
};

type Actions = {
  show: (view: DirectoryView) => void;
  updateRoadmap: (projectId: string[], selected: boolean, onError?: () => void) => void;
  isOnboardingModeActive: () => boolean;
  isRecommendedExpanded: () => boolean;
};

export type DirectoryStore = SignalObject<Store> & Actions;

function createDirectoryStore(): DirectoryStore {
  const initialState: Store = {
    activeView: DirectoryView.EXPLORE,
    onboardingModeActive: !GlobalStore.user.isLoggedIn.value,
    recommendedExpanded: GlobalStore.user.isLoggedIn.value,
  };

  const directoryStore = signalObject(initialState);
  const projectsSlice = useProjects();
  const myProjectsSlice = useMyProjects();
  const searchSlice = useSearch();
  const scheduleSlice = useSchedule();

  return {
    ...directoryStore,
    isOnboardingModeActive: isOnboardingModeActiveSelector(GlobalStore),
    isRecommendedExpanded: isRecommendedExpandedSelector(directoryStore),

    show: showFunc(directoryStore),
    updateRoadmap: updateRoadmapFunc(projectsSlice, myProjectsSlice, searchSlice, scheduleSlice, GlobalStore),
  };
}

function showFunc(directoryStore: SignalObject<Store>): (view: DirectoryView) => void {
  return (view: DirectoryView) => {
    directoryStore.activeView.value = view;
  };
}

function isOnboardingModeActiveSelector(globalStore: GlobalStore): () => boolean {
  return () => !globalStore.user.isLoggedIn.value;
}

function isRecommendedExpandedSelector(directoryStore: SignalObject<Store>): () => boolean {
  return () => directoryStore.recommendedExpanded.value;
}

/**
 * This function updates the selected status of a project across:
 * - Projects (useProjects)
 * - My Projects (useMyProjects)
 * - Current search results (useSearch)
 */
function updateRoadmapFunc(
  projectsSlice: ReturnType<typeof useProjects>,
  myProjectsSlice: ReturnType<typeof useMyProjects>,
  searchSlice: ReturnType<typeof useSearch>,
  scheduleSlice: ReturnType<typeof useSchedule>,
  globalStore: GlobalStore,
): (id: string[], selected: boolean, onError?: () => void) => void {
  if (globalStore.user.isLoggedIn.value === false) {
    return () => {
      openModal("login", {});
    };
  }

  const updateMutation = useUpdateSelectedProjectsMutation();

  const add = (ids: string[], projects: Project[]) => {
    projectsSlice.selectProject(ids);
    myProjectsSlice.addToRoadmap(projects);
    searchSlice.updateSelected(ids, true);
  };

  const remove = (ids: string[]) => {
    projectsSlice.unselectProject(ids);
    myProjectsSlice.removeFromRoadmap(ids);
    searchSlice.updateSelected(ids, false);
  };

  return (projectIds: string[], selected: boolean, onError?: () => void) => {
    scheduleSlice.isLoading.value = true;

    const projects = projectIds.map(projectsSlice.getProject).filter(p => p != null) as Project[];

    if (selected) {
      add(projectIds, projects);
    } else {
      remove(projectIds);
    }

    if (!myProjectsSlice.data.value) {
      return;
    }

    updateMutation.mutate(myProjectsSlice.data.value, {
      onSuccess: () => {
        scheduleSlice.fetch({});
      },
      onError: () => {
        if (selected) {
          remove(projectIds);
        } else {
          add(projectIds, projects);
        }

        onError?.();
      },
    });
  };
}

export const DirectoryStore = createDirectoryStore();
