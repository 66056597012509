import { bind, Component, h, tag } from "omi";
import classNames from "classnames";

import { IconName } from "@/components/atoms/nw-icon/icons";
import { NWBadge, NWIcon, NWTooltip, TooltipProps } from "@/components/atoms";
import { AuthService } from "@/services/authService";
import { addToClipboard } from "@/utils/clipboard";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

type Props = {};

type MenuItem = {
  type: "button" | "link" | "divider";
  icon?: IconName;
  text?: string;
  tooltipOptions?: TooltipProps;
  color?: "red" | "gray";
  comingSoon?: boolean;
  callback?: () => void;
  href?: string;
};

@tag("toolbar-views-user")
export class NWToolbarViewUser extends Component<Props> {
  static css = [tailwind];
  private authService: AuthService | undefined;

  async installed() {
    this.authService = new AuthService();
  }

  private createMenuItems(): MenuItem[] {
    const items: MenuItem[] = [
      {
        type: "button",
        icon: "rows",
        text: "Portfolio",
        comingSoon: true,
        callback: () => {
          // TODO track analytics for clicks on this
          console.log("Portfolio");
        },
      },
      {
        type: "button",
        icon: "heart-hand",
        text: "Invite a friend",
        tooltipOptions: {
          content: "Copied! Send to a friend 🚀",
          trigger: "click",
          placement: "top",
          strategy: "absolute",
        },
        callback: this.handleInviteCopy,
      },
      { type: "divider" },
      {
        type: "link",
        icon: "file",
        text: "Terms & Conditions",
        href: "/terms",
      },
      {
        type: "link",
        icon: "key",
        text: "Privacy policy",
        href: "/privacy",
      },
      { type: "divider" },
      {
        type: "button",
        icon: "logout",
        text: "Log out",
        callback: this.handleLogout,
      },
    ];

    if (Store.features.isAdmin) {
      items.push(
        {
          type: "divider",
        },
        {
          type: "button",
          text: "Admin settings",
          icon: "admin",
          callback: () => Store.app.showToolbarView("admin"),
          color: "red",
        },
      );
    }
    return items;
  }

  @bind
  private async handleInviteCopy() {
    const inviteLink = "https://link.nextwork.org/app?utm_source=app&utm_medium=nav&utm_campaign=referral";
    await addToClipboard(inviteLink);

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackInviteLinkCopied();
    });
  }

  @bind
  private handleLogout() {
    this.authService?.logout();
  }

  @bind
  private handleItemClick(item: MenuItem) {
    if (item.type === "button" && item.callback) {
      item.callback();
    }
  }

  renderMenuItems() {
    const menuItems = this.createMenuItems();
    const itemStyles =
      "text-gray-700 cursor-pointer select-none text-sm px-4 h-10 flex items-center hover:bg-gray-50 space-x-2 w-full";

    return (
      <ul class="flex-1 my-1">
        {menuItems.map(item => {
          if (item.type === "divider") return <li class="border-b bg-gray-200 w-full" />;

          const content = (
            <>
              {item.icon && (
                <div class="text-gray-500">
                  <NWIcon name={item.icon} width={16} height={16} />
                </div>
              )}
              <span class="text-nowrap whitespace-nowrap flex-grow">{item.text}</span>
            </>
          );

          if (item.type === "link" && item.href) {
            return (
              <a class={itemStyles} tabIndex={0} href={item.href} target="_blank">
                {content}
              </a>
            );
          }

          const listItem = (
            <li
              onClick={() => this.handleItemClick(item)}
              tabIndex={0}
              class={classNames(itemStyles, { "bg-error-100": item.color === "red" })}
            >
              {content}
              {item.comingSoon && (
                <NWBadge color="gray-filled" padding="xs">
                  <span class="text-gray-700 normal-case">Coming soon</span>
                </NWBadge>
              )}
            </li>
          );

          if (item.tooltipOptions) {
            return <NWTooltip {...item.tooltipOptions}>{listItem}</NWTooltip>;
          }

          return listItem;
        })}
      </ul>
    );
  }

  render() {
    const user = Store.user.info.value;

    return (
      <div class="bg-white border border-gray-200 rounded-lg shadow-lg w-[216px] md:w-[230px] relative">
        <div className="flex flex-col">
          <div class="px-4 py-3 relative">
            {user?.name ? (
              <>
                <div class="flex items-center justify-between">
                  <p className="text-gray-700 text-sm font-medium truncate mr-2 text-ellipsis overflow-hidden">
                    {user.name}
                  </p>
                  {Store.user.isPro() && (
                    <NWBadge
                      color="brand"
                      padding="xs"
                      iconSize={{ width: 7, height: 12 }}
                      iconOnly={false}
                      icon="keyhole"
                    >
                      PRO
                    </NWBadge>
                  )}
                </div>
                <p className="text-gray-500 text-sm truncate">{user?.email}</p>
              </>
            ) : (
              <div class="flex items-center justify-between">
                <p className="text-gray-700 text-sm font-medium truncate mr-2">{user?.email || "NextWork Student"}</p>
                {Store.user.isPro() && (
                  <NWBadge
                    color="brand"
                    padding="xs"
                    iconSize={{ width: 7, height: 12 }}
                    iconOnly={false}
                    icon="keyhole"
                  >
                    PRO
                  </NWBadge>
                )}
              </div>
            )}
          </div>
          <div class="border-b bg-gray-200 w-full"></div>
          {this.renderMenuItems()}
        </div>
      </div>
    );
  }
}
