import { bind, Component, createRef, h, tag } from "omi";

import { OrderBy } from "@/pages/project/features/directory/store/models";
import { NWError } from "@/components/base/nw-error";
import type { Project } from "@/api";
import { NWButton } from "@/components";
import { tailwind } from "@/tailwind";

import { ProjectListSkeleton } from "../../components/projectList/skeleton";
import { DirectoryStore } from "../../store";
import { DirectoryView } from "../../views";
import { ListItem } from "./listItem";

type ProjectProps = {
  type: "projects";
  projects: Project[];
  isLoading: boolean;
  error: unknown | undefined;
};

type ProjectsByCategory = {
  type: "grouped";
  projectsByCategory: [string, Project[]][];
  isLoading: boolean;
  error: unknown | undefined;
  orderBy: string;
};

export function isProjectProps(props: Props): props is ProjectProps {
  return props.type === "projects";
}

type Props = ProjectProps | ProjectsByCategory;

@tag("nw-directory-project-list")
export class ProjectList extends Component<Props> {
  static css = [tailwind];

  private scrollContainerRef = createRef<HTMLDivElement>();

  installed() {
    this.scrollContainerRef.current?.addEventListener("wheel", this.handleOverscroll);
  }

  uninstall() {
    this.scrollContainerRef.current?.removeEventListener("wheel", this.handleOverscroll);
  }

  @bind
  private handleOverscroll(event: WheelEvent) {
    if (!this.scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollContainerRef.current!;
    if ((event.deltaY < 0 && scrollTop === 0) || (event.deltaY > 0 && scrollTop + clientHeight >= scrollHeight)) {
      event.preventDefault();
    }
  }

  private navigateToExplore = () => {
    DirectoryStore.show(DirectoryView.EXPLORE);
  };

  render(props: Props) {
    const isEmpty = isProjectProps(props) ? props.projects.length === 0 : props.projectsByCategory?.length === 0;

    if (props.isLoading) {
      return (
        <div className="animate-fadeInFast">
          <ProjectListSkeleton />
        </div>
      );
    }

    if (props.error != null) {
      return (
        <div class="animate-fadeInFast">
          <NWError />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <div className="h-full w-full py-40">
          <div className="flex flex-col items-center justify-center space-y-4">
            <NWButton
              size="icon"
              type="icon"
              icon="plus"
              iconColor="text-gray-700"
              iconOnly
              iconBorder
              onClick={this.navigateToExplore}
            />

            <div className="space-y-1">
              <div className="text-lg font-semibold text-center text-gray-900">Let's start learning</div>
              <div className="text-sm text-gray-500">Add projects to get started!</div>
            </div>
          </div>
        </div>
      );
    }

    if (isProjectProps(props)) {
      return (
        <div className="animate-fadeInFast flex flex-col gap-2 pb-4 pt-0 flex-1 min-h-0 overflow-y-auto">
          {props.projects.map(p => (
            <div key={p.id} className="flex flex-col gap-2">
              <ListItem type="project" project={p} />
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="animate-fadeInFast flex flex-col gap-2 pb-4 pt-0 flex-1 min-h-0 overflow-y-auto">
        {props.projectsByCategory?.map(([category, projects]) => (
          <div key={category} className="flex flex-col gap-2">
            {props.orderBy === OrderBy.TOPIC && (
              <div className="text-xs font-semibold -tracking-tight uppercase text-gray-600 leading-6">
                {category} series
              </div>
            )}
            {projects?.map(project => <ListItem type="project" project={project} alwaysShowUnselect={false} />)}
          </div>
        ))}
      </div>
    );
  }
}
