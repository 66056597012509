import { bind, classNames, Component, h, OmiProps, signal, tag } from "omi";

import { AuthEvents } from "@/components/nw-auth-manager/events";
import { NWAvatar } from "@/components/base";
import { COMMUNITY_LINK } from "@/globals/links";
import { ErrorService } from "@/services/errorService";
import { NWTooltip } from "@/components";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

import { ToolbarItem } from "./components/toolbarItem";
import { NWToolbarViewManager } from "./views/view-manager";

type Controlled = {
  type: "controlled";
  show: boolean;
  withBackground?: boolean;
};

type Uncontrolled = {
  type: "uncontrolled";
  withBackground?: boolean;
};

type Props = Controlled | Uncontrolled;

@tag("nw-toolbar")
export class NWToolbar extends Component<Props> {
  static css = [tailwind];

  private items: ToolbarItem[] = [
    {
      type: "button",
      iconLeft: "learning-nextwork",
      name: "My projects",
      color: "gray",
      iconOnly: true,
      onClick: () => {
        if (Store.features.hasFeature("projects.navV2")) {
          Store.app.showToolbarView("directory");
          import("@/services/analyticsService").then(({ useAnalyticsService }) => {
            useAnalyticsService().trackSidebarOpen("toolbar");
          });
        } else {
          Store.app.toggleNav(!Store.app.isNavOpen.value, "toolbar");
        }
      },
      active: () => Store.app.isNavOpen.value,
    },
    {
      type: "link",
      name: "Stuck? Ask a question",
      icon: "message-question-circle",
      href: COMMUNITY_LINK,
    },
  ];
  private afterScrollHideLimit = 1200;

  private lastScrollTop = signal(0);
  private isRevealed = signal(true);

  installed() {
    if (Store.user.isLoggedIn.value) {
      addEventListener("scroll", this.handleOnScroll, { passive: true });
    }
  }

  uninstall() {
    if (Store.user.isLoggedIn.value) {
      removeEventListener("scroll", this.handleOnScroll);
    }
  }

  receiveProps(props: OmiProps<Props> | Props) {
    return !!isControlled(props);
  }

  @bind
  ready() {
    setTimeout(() => {
      try {
        const scrollY = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollY <= this.afterScrollHideLimit) {
          this.isRevealed.value = true;
        }
      } catch (e) {
        ErrorService.report(e);
      }
    }, 200);
  }

  @bind
  private handleSignIn() {
    const loginEvent = new Event(AuthEvents.LoginClicked);
    dispatchEvent(loginEvent);
  }

  @bind
  private handleOnScroll() {
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollY > this.afterScrollHideLimit) {
      this.isRevealed.value = scrollY < this.lastScrollTop.value;
      this.lastScrollTop.value = scrollY <= 0 ? 0 : scrollY;
    } else {
      this.isRevealed.value = true;
    }
  }

  @bind
  private handleBackgroundClick() {
    Store.app.showToolbarView(undefined);
  }

  render({ withBackground = true, ...props }: Props) {
    const isControlledToolbar = isControlled(props);
    const isRevealed = isControlledToolbar ? props.show : this.isRevealed.value || Store.app.isNavOpen.value;
    const hasActiveView = Store.app.activeToolbarView.value != null;

    return (
      <>
        {withBackground && (
          <div
            onClick={this.handleBackgroundClick}
            className={classNames(
              "fixed top-0 left-0 right-0 bottom-0 bg-black/10 backdrop-blur-sm z-[500] transition-opacity duration-300 ease-in-out",
              {
                "opacity-0 pointer-events-none": !hasActiveView,
                "opacity-100": hasActiveView,
              },
            )}
          />
        )}

        <div
          class={classNames(
            "fixed bottom-0 left-1/2 -translate-x-1/2 h-24 sm:h-24 flex flex-col space-y-1 justify-center py-4 z-[501] w-full sm:min-w-[560px] sm:w-full sm:max-w-[816px] transition-all ease-in-out duration-300",
            { "pointer-events-none": isRevealed, group: !isRevealed },
          )}
        >
          <div
            class={classNames(
              "group-hover:translate-y-0 pointer-events-auto transition-transform ease-in-out duration-300 fixed bottom-4 left-1/2 -translate-x-1/2 flex flex-col justify-center items-center space-y-2",
              {
                "translate-y-0": isRevealed,
                "translate-y-[300%]": !isRevealed,
              },
            )}
          >
            <div
              class={classNames(
                "transition-all ease-in-out duration-300 group-hover:translate-y-0 flex items-center justify-center",
              )}
            >
              <NWToolbarViewManager />
            </div>

            <div
              id="pill-container"
              class={classNames(
                {
                  "!animate-slideUp": isControlledToolbar && props.show,
                  "transition-all duration-200 ease-in-out": !isControlledToolbar,
                },
                "shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] pointer-events-auto bg-white py-4 px-8 border border-gray-200 rounded-3xl overflow-x-hidden flex items-center justify-between transition-[width] duration-800",
                { "w-full max-w-[90%] mx-auto md:max-w-2xl": hasActiveView, "w-full flex-1 sm:w-60": !hasActiveView },
                { "min-w-60": Store.user.isLoggedIn.value, "min-w-80": !Store.user.isLoggedIn.value },
              )}
            >
              <div class="flex items-center space-x-3">
                {this.items.map(item => (
                  <ToolbarItem {...item} />
                ))}
              </div>

              <div class="flex items-center space-x-5">
                <ToolbarItem type="divider"></ToolbarItem>

                {Store.user.isLoggedIn.value ? (
                  <NWTooltip content="My profile" placement="top">
                    <NWAvatar withProBorder onClick={() => Store.app.showToolbarView("user")}></NWAvatar>
                  </NWTooltip>
                ) : (
                  <ToolbarItem
                    type="button"
                    name="Sign in"
                    iconRight="arrow-right"
                    onClick={this.handleSignIn}
                    color="brand"
                    semibold
                    active={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function isControlled(props: Props): props is Controlled {
  return (props as Controlled).type === "controlled";
}
