import { h } from "omi";

export function LearnBackground() {
  return (
    <div className="absolute animate-fadeInFast bottom-0 inset-x-0 mx-auto w-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1512 350"
        fill="none"
        className="w-full h-auto mx-auto animate-fadeInFast"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M0 357.131V0h86.318v287.03h148.573v70.101H0ZM259.933 357.131V0h249.016v70.101H346.252v73.24h149.967v70.275H346.252v73.414h162.697v70.101H259.933ZM620.615 357.131h-92.77L648.342 0h114.917l120.497 357.131h-92.771l-83.877-267.15h-2.79l-83.703 267.15Zm-12.381-140.55h193.912v65.567H608.234v-65.567ZM902.564 357.131V0h147.526c26.74 0 49.82 4.825 69.23 14.474 19.53 9.532 34.58 23.25 45.16 41.153 10.58 17.787 15.87 38.887 15.87 63.301 0 24.762-5.4 45.804-16.21 63.125-10.82 17.206-26.16 30.343-46.04 39.41-19.88 8.952-43.42 13.428-70.63 13.428h-93.289v-68.009h77.249c13.02 0 23.89-1.685 32.61-5.057 8.84-3.487 15.52-8.719 20.05-15.694 4.54-7.092 6.81-16.159 6.81-27.203 0-11.045-2.27-20.17-6.81-27.378-4.53-7.324-11.21-12.788-20.05-16.392-8.83-3.72-19.7-5.58-32.61-5.58h-42.547V357.13h-86.319Zm201.066-163.22 88.93 163.22h-94.17l-87.19-163.22h92.43ZM1512 0v357.131h-73.24l-142.12-206.117h-2.27v206.117h-86.32V0h74.29l140.55 205.769h2.97V0H1512Z"
          fill="#F5F7FA"
        />
      </svg>
    </div>
  );
}
