import { h } from "omi";

type Props = {
  size?: "md" | "lg";
};
export function SearchListSkeleton({ size = "lg" }: Props) {
  const items = size === "lg" ? [1, 2] : [1];

  return (
    <div class="animate-fadeInFast">
      <div class="animate-pulse space-y-10">
        {items.map(idx => (
          <div class="space-y-2">
            <div class="h-5 bg-gray-100 rounded-md w-1/4"></div>
            <div class="space-y-2">
              <div class="px-0 space-y-3">
                <div class="h-14 bg-gray-100 rounded-md w-full"></div>
                <div class="h-14 bg-gray-100 rounded-md w-full"></div>
                <div class="h-14 bg-gray-100 rounded-md w-full"></div>
                {(idx === 2 || size == "md") && <div class="h-14 bg-gray-100 rounded-md w-full"></div>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
