import { classNames, Component, h, tag } from "omi";

import { DirectoryStore } from "@/pages/project/features/directory/store";
import { NWIcon } from "@/components/atoms/nw-icon";
import { NWError } from "@/components/base/nw-error";
import { SearchResult } from "@/api";
import { tailwind } from "@/tailwind";

import { ListItem } from "./listItem";
import { SearchListSkeleton } from "./skeleton";

type Props = {
  results: SearchResult[];
  isLoading: boolean;
  error: unknown | undefined;
  hasSearchTerm: boolean;
};

@tag("nw-directory-search-list")
export class SearchList extends Component<Props> {
  static css = [tailwind];

  render({ results = [], isLoading, error, hasSearchTerm }: Props) {
    const isEmpty = results.length === 0;
    const series = results.filter(r => r.type === "course");
    const projects = results.filter(r => r.type === "project");

    if (isLoading) {
      return (
        <div className="animate-fadeInFast">
          <SearchListSkeleton size={DirectoryStore.isOnboardingModeActive() ? "md" : "lg"} />
        </div>
      );
    }

    if (error != null) {
      return (
        <div class="animate-fadeInFast">
          <NWError />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <div className="h-full w-full py-40 animate-fadeInFast">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="flex items-center shrink-0 justify-center w-12 h-12 rounded-xl border">
              <NWIcon name="search" className="text-gray-700" width={24} height={24} />
            </div>
            <div className="space-y-1">
              <div className="text-lg text-center font-semibold text-gray-900">No results found</div>
              <div className="text-sm text-gray-500">Try searching for something else</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4 pb-4 pt-0 flex-1 min-h-0 overflow-y-auto">
        <div className={classNames("space-y-1", { hidden: series.length === 0 || !hasSearchTerm })}>
          <div className="animate-fadeInFast text-xs font-semibold -tracking-tight uppercase text-gray-600 leading-6 mb-1">
            Series ({series.length})
          </div>
          {series.map(
            (result, idx) =>
              result.type === "course" && (
                <div key={idx} className="flex flex-col gap-2">
                  <ListItem {...result} type="course" id={result.title} />
                </div>
              ),
          )}
        </div>

        <div className={classNames("space-y-1", { hidden: projects.length === 0 })}>
          <div className="animate-fadeInFast text-xs font-semibold -tracking-tight uppercase text-gray-600 leading-6 mb-1">
            {!hasSearchTerm ? "All Projects" : "Projects"} ({projects.length})
          </div>
          {projects.map(
            (result, idx) =>
              result.type === "project" && (
                <div key={idx} className="flex flex-col gap-2">
                  <ListItem {...result} type="project" />
                </div>
              ),
          )}
        </div>
      </div>
    );
  }
}
