import { batch, bind, classNames, Component, h, OmiProps, signal, tag } from "omi";
import debounce from "lodash-es/debounce";
import isNumber from "lodash-es/isNumber";

import { tailwind } from "@/tailwind";

type Props = {
  /**
   * The fixed width of the panel in pixels
   */
  width?: number;
  /**
   * Whether the panel should expand to full viewport width when fully opened
   */
  fullWidth?: boolean;
  /**
   * Optional className to apply to the panel container
   */
  className?: string;
  /**
   * Optional className to apply to the drag handle
   */
  dragHandleClassName?: string;
  /**
   * Callback fired when panel visibility changes
   * @param isVisible Whether the panel is now visible
   */
  onPanelVisibilityChange?: (isVisible: boolean) => void;
  /**
   * Controls whether the panel is visible
   */
  visible?: boolean;
  /**
   * Controls whether the drag handle is visible
   * @default true
   */
  showDragHandle?: boolean;
  /**
   * Optional message to display to prompt the user to drag the panel
   */
  dragHintComponent?: JSX.Element;
};

/**
 * A draggable panel component that slides horizontally over content
 */
@tag("nw-draggable-panel")
export class NWDraggablePanel extends Component<Props> {
  static css = [
    tailwind,
    `
    .no-select {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .panel-transition {
      transition: transform 300ms linear(
        0,
        0.0346 2.07%,
        0.1407 4.43%,
        0.9383 16.72%,
        1.0574 20.43%,
        1.1193 24.31%,
        1.1329 26.95%,
        1.1236 29.93%,
        1.0068 43.09%,
        0.9866 51%,
        1.0023 76.88%,
        1
      );
    }
    `,
  ];

  static defaultProps = {
    width: 320,
    fullWidth: false,
  };

  private readonly HANDLE_WIDTH = 36;
  private readonly panelWidth = signal<number>(320);

  private translateX = signal<number>(-320);
  private isDragging = signal<boolean>(false);
  private dragStartX = signal<number>(0);
  private dragStartTranslate = signal<number>(0);
  private viewportWidth = signal<number>(window.innerWidth);
  private isOpen = signal<boolean>(false);
  private isAnimating = signal<boolean>(false);
  private isResizing = signal<boolean>(false);
  private readonly RESIZE_DEBOUNCE_MS = 200;

  private animationFrame: number | null = null;

  private isInitialRender = signal<boolean>(true);
  private isPostResize = signal<boolean>(false);
  private isHandlingDoubleClick = signal<boolean>(false);

  installed() {
    this.panelWidth.value = this.props.width || 320;

    if (this.props.visible) {
      this.isOpen.value = true;
      this.translateX.value = 0;
    } else {
      this.translateX.value = -this.getMaxWidth();
    }

    if (this.props.fullWidth) {
      window.addEventListener("resize", this.handleResizeStart, { passive: true });
      window.addEventListener("resize", this.debouncedHandleResize, { passive: true });
    }
  }

  uninstall() {
    if (this.props.fullWidth) {
      window.removeEventListener("resize", this.handleResizeStart);
      window.removeEventListener("resize", this.debouncedHandleResize);
    }

    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }

  ready() {
    if (this.isInitialRender.value) {
      this.isInitialRender.value = false;
    }
  }

  @bind
  private handleResizeStart() {
    if (this.isAnimating.value) {
      this.isAnimating.value = false;
    }
    this.isResizing.value = true;
    this.isPostResize.value = true;
  }

  @bind
  private handleResizeEnd() {
    this.isResizing.value = false;
    setTimeout(() => {
      this.isPostResize.value = false;
    }, 300);
  }

  private debouncedHandleResize = debounce(() => {
    const newWidth = window.innerWidth;
    this.viewportWidth.value = newWidth;

    if (this.props.fullWidth) {
      const maxNewWidth = newWidth - this.HANDLE_WIDTH;

      if (this.isOpen.value) {
        this.translateX.value = 0;
      } else {
        this.translateX.value = -maxNewWidth;
      }
    }

    requestAnimationFrame(() => {
      setTimeout(this.handleResizeEnd, 50);
    });
  }, this.RESIZE_DEBOUNCE_MS);

  @bind
  private getMaxWidth(): number {
    if (!this.props.fullWidth) {
      return this.panelWidth.value;
    }

    return Math.max(this.viewportWidth.value - this.HANDLE_WIDTH, this.HANDLE_WIDTH);
  }

  @bind
  private snapToPosition(currentTranslate: number) {
    if (this.isAnimating.value) {
      return;
    }

    const maxWidth = this.getMaxWidth();
    currentTranslate = Math.max(-maxWidth, Math.min(0, currentTranslate));
    const openPercentage = Math.max(0, Math.min(1, (currentTranslate + maxWidth) / maxWidth));

    this.isAnimating.value = true;

    const BUFFER = 0.05; // 5% buffer zone to stop deadzone locks

    if (this.isOpen.value) {
      const closeThreshold = 0.8 + BUFFER;

      if (openPercentage < closeThreshold) {
        this.translateX.value = -maxWidth;
        this.isOpen.value = false;
        this.props.onPanelVisibilityChange?.(false);
      } else {
        this.translateX.value = 0;
      }
    } else {
      const openThreshold = 0.2 - BUFFER;

      if (openPercentage > openThreshold) {
        this.translateX.value = 0;
        this.isOpen.value = true;
        this.props.onPanelVisibilityChange?.(true);
      } else {
        this.translateX.value = -maxWidth;
      }
    }

    requestAnimationFrame(() => {
      setTimeout(() => {
        this.isAnimating.value = false;
      }, 300);
    });
  }

  @bind
  private handleDragStart(e: MouseEvent) {
    if (this.isAnimating.value || this.isDragging.value) return;

    e.preventDefault();
    this.isDragging.value = true;
    this.dragStartX.value = e.clientX;
    this.dragStartTranslate.value = this.translateX.value;

    // Ensure we clean up any existing listeners first
    this.cleanupDragListeners();

    document.body.classList.add("select-none");
    document.addEventListener("mousemove", this.handleDrag);
    document.addEventListener("mouseup", this.handleDragEnd);
    document.addEventListener("mouseleave", this.handleDragEnd);
    document.addEventListener("mouseout", this.handleDragEnd);
  }

  @bind
  private handleDrag(e: MouseEvent) {
    if (!this.isDragging.value) return;

    e.preventDefault();
    e.stopPropagation();

    const delta = e.clientX - this.dragStartX.value;
    const maxWidth = this.getMaxWidth();
    const newTranslate = Math.max(-maxWidth, Math.min(0, this.dragStartTranslate.value + delta));

    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }

    this.animationFrame = requestAnimationFrame(() => {
      this.translateX.value = newTranslate;
    });
  }

  @bind
  private handleDragEnd(e?: MouseEvent) {
    if (!this.isDragging.value) return;

    if (e) {
      e.preventDefault();
    }

    this.isDragging.value = false;
    this.cleanupDragListeners();

    if (isNumber(this.translateX.value)) {
      this.snapToPosition(this.translateX.value);
    } else {
      this.snapToPosition(this.dragStartTranslate.value);
    }
  }

  @bind
  private cleanupDragListeners() {
    document.body.classList.remove("select-none");
    document.removeEventListener("mousemove", this.handleDrag);
    document.removeEventListener("mouseup", this.handleDragEnd);
    document.removeEventListener("mouseleave", this.handleDragEnd);
    document.removeEventListener("mouseout", this.handleDragEnd);

    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
  }

  @bind
  private handleDoubleClick() {
    if (this.isHandlingDoubleClick.value) return;

    this.isHandlingDoubleClick.value = true;

    if (this.isDragging.value) {
      this.cleanupDragListeners();
    }

    const maxWidth = this.getMaxWidth();

    batch(() => {
      this.isAnimating.value = true;

      if (this.isOpen.value) {
        this.translateX.value = -maxWidth;
        this.isOpen.value = false;
        this.props.onPanelVisibilityChange?.(false);
      } else {
        this.translateX.value = 0;
        this.isOpen.value = true;
        this.props.onPanelVisibilityChange?.(true);
      }
    });

    requestAnimationFrame(() => {
      setTimeout(() => {
        this.isAnimating.value = false;
        this.isHandlingDoubleClick.value = false;
      }, 300);
    });
  }

  @bind
  private handleWheel(e: WheelEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  render({ dragHintComponent, className = "", fullWidth = false, showDragHandle = true }: OmiProps<Props>) {
    const currentWidth = fullWidth ? this.viewportWidth.value - this.HANDLE_WIDTH : this.panelWidth.value;

    // Only apply transform styles after initial render to prevent flickering on Safari
    const transformStyle =
      this.isInitialRender.value || this.isPostResize.value
        ? {}
        : { transform: `translate3d(${this.translateX.value}px, 0, 0)` };

    return (
      <div>
        <div
          className={classNames(
            "fixed left-0 top-0 h-screen z-20 bg-white will-change-transform translate-z-0 backface-hidden",
            {
              "transition-none": this.isDragging.value,
              "panel-transition": !this.isDragging.value,
              hidden: this.isInitialRender.value || (!this.isOpen.value && this.isPostResize.value),
            },
            className,
          )}
          style={{
            width: `${currentWidth}px`,
            ...transformStyle,
          }}
          onWheel={this.handleWheel}
        >
          <slot name="panel"></slot>
        </div>

        <div
          className={classNames("backface-hidden", {
            "transition-none": this.isDragging.value,
            "panel-transition": !this.isInitialRender.value && !this.isDragging.value,
            "backface-hidden will-change-transform":
              this.isDragging.value || this.isOpen.value || this.isAnimating.value || this.isHandlingDoubleClick.value,
          })}
          style={{
            ...(this.isInitialRender.value
              ? {}
              : this.isDragging.value || this.isOpen.value || this.isAnimating.value || this.isHandlingDoubleClick.value
                ? { transform: `translate3d(${Math.max(0, this.translateX.value + currentWidth)}px, 0, 0)` }
                : {}),
          }}
        >
          <slot name="main"></slot>
        </div>

        <div
          id="drag-container"
          className={classNames(
            "fixed top-0 bottom-0 group cursor-ew-resize flex items-center justify-center select-none will-change-[transform,opacity] translate-z-0 backface-hidden transition-opacity duration-300 ease-in-out",
            { hidden: !showDragHandle, "animate-fadeInFast": showDragHandle },
            {
              "transition-none": this.isDragging.value || this.isPostResize.value,
              "panel-transition": !this.isDragging.value && !this.isPostResize.value,
              "opacity-0 pointer-events-none": this.isResizing.value,
              "opacity-100 pointer-events-auto": !this.isResizing.value,
            },
            { "bg-white": this.isOpen.value },
          )}
          style={{
            left: `${Math.max(this.HANDLE_WIDTH, currentWidth - this.HANDLE_WIDTH / 2)}px`,
            transform: `translate3d(${this.translateX.value}px, 0, 0)`,
            width: `${this.HANDLE_WIDTH * 2}px`,
          }}
          onMouseDown={this.handleDragStart}
          onDblClick={this.handleDoubleClick}
        >
          <div className="h-32 mx-auto w-1.5 transition-colors rounded-full bg-gray-200 group-hover:bg-gray-300"></div>
          {dragHintComponent ? dragHintComponent : <></>}
        </div>
      </div>
    );
  }
}
