import isNil from "lodash-es/isNil";

import { networkSignal } from "@/utils/signals";
import { Preferences } from "@/api";
import { Store } from "@/store";
import { initApi } from "@/utils";

const api = initApi();
const userPreferences = networkSignal<Preferences>();

export function useUserPreferencesMutation() {
  const mutate = ({ selectedCategories, hoursPerDay, daysPerWeek }: Partial<Preferences>) =>
    userPreferences.run(async () => {
      if (selectedCategories) {
        Store.user.preferences.updateCategories(selectedCategories);
      }

      if (!isNil(hoursPerDay) && !isNil(daysPerWeek)) {
        Store.user.preferences.updateTimePreferences(hoursPerDay, daysPerWeek);
      }

      return await updatePreferences();
    });

  const mutateTime = (hoursPerDay: number, daysPerWeek: number) => mutate({ hoursPerDay, daysPerWeek });

  return { ...userPreferences, mutate, mutateTime };
}

async function updatePreferences() {
  const preferences = Store.user.preferences.getPreferencesDTO();
  return await api.setUserPreferences({ preferences });
}
