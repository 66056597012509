import { Project } from "@/api";
import { initApi } from "@/utils";

import { getSelectedTime } from "../../utils";

const api = initApi();

type MutationOptions = {
  onSuccess?: (projects: Project[]) => void;
  onError?: () => void;
};

export function useUpdateSelectedProjectsMutation() {
  const mutate = async (projects: Project[], { onSuccess, onError }: MutationOptions = {}) => {
    try {
      const payload = projects.map(project => ({
        projectId: project.id!,
        selectedTime: getSelectedTime(project),
      }));

      const res = await api.updateProjectSelection({ projectSelection: payload });
      onSuccess?.(res);
      return res;
    } catch (error) {
      onError?.();
    }
  };

  return { mutate };
}
