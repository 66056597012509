import { classNames, Component, h, tag } from "omi";

import { NWTabs } from "@/components/base/nw-tabs";
import { tailwind } from "@/tailwind";

import { useProjects } from "./store/queries/useProjects";
import { useSearch } from "./store/queries/useSearch";
import { NWDirectoryExploreView } from "./views/explore";
import { NWDirectoryMyProjects } from "./views/myProjects";
import { DirectoryStore } from "./store";
import { DirectoryView } from "./views";

type Props = {};

@tag("nw-project-features-directory")
export class NWProjectDirectory extends Component<Props> {
  static css = [tailwind];

  installed() {
    useProjects().fetch();
    useSearch().fetch();
  }

  handleTabChange = (tabId: string) => {
    DirectoryStore.show(tabId as DirectoryView);

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackDirectoryTabChanged(tabId);
    });
  };

  renderOnboarding() {
    const hasSearchTerm = useSearch().searchQuery.value.length > 0;
    const isRecommendedExpanded = DirectoryStore.isRecommendedExpanded();

    return (
      <div
        class={classNames(
          "bg-white border border-gray-200 rounded-3xl max-w-[90%] mx-auto md:max-w-screen-sm w-screen p-6 !pb-0",
          "h-[16rem] transition-[height] duration-500",
          {
            "!h-[32rem]": hasSearchTerm || isRecommendedExpanded,
          },
        )}
      >
        <NWDirectoryExploreView />
      </div>
    );
  }

  render() {
    if (DirectoryStore.isOnboardingModeActive()) {
      return this.renderOnboarding();
    }

    return (
      <div class="bg-white border border-gray-200 rounded-3xl max-w-[90%] mx-auto md:max-w-screen-sm w-screen max-h-[84svh] h-screen p-6">
        <NWTabs
          tabs={[
            {
              id: DirectoryView.EXPLORE,
              label: "Explore",
              content: <NWDirectoryExploreView />,
            },
            {
              id: DirectoryView.MY_PROJECTS,
              label: "My Projects",
              content: <NWDirectoryMyProjects />,
            },
          ]}
          onTabChange={this.handleTabChange}
          activeTabId={DirectoryStore.activeView.value}
        />
      </div>
    );
  }
}
